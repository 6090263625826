const pad = (num: number, size: number) => {
  let newNum = num.toString();
  while (newNum.length < size) newNum = "0" + newNum;
  return newNum;
};

export const secondsToTime = (num: number) => {
  const minutes = Math.floor(num / 60);
  const seconds = num - minutes * 60;
  return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
};

export const convertDate = (date: string | undefined | null) => {
  if (date === undefined || date === "") return "Mon Year";
  if (date === null) return "Present";
  const parsed = new Date(Date.parse(date));
  const month = parsed.toLocaleString("default", { month: "short" });
  return `${month} ${parsed.getFullYear()}`;
};

export const YYYYMMDDToISO = (date: string) => {
  return date ? new Date(Date.parse(date)).toISOString() : "";
};

export const ISOtoYYYYMMDD = (date: string) => {
  if (!date) return "";
  const parsed = new Date(Date.parse(date));
  const YYYYMMDD = `${parsed.getFullYear()}-${pad(
    parsed.getMonth() + 1,
    2
  )}-${pad(parsed.getDate(), 2)}`;
  return YYYYMMDD;
};
