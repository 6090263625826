import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import {
  getState,
  updateAppLayout,
  updateContextId,
  updateGlobalError,
  updateLoadingMessage,
  updateMode,
  updateOnboardingScreenIndex,
  updateProgressStepsCurrent,
  updateProgressStepsTotal,
  updateProject,
  updateQuestion,
  updateResumeUploadResource,
  updateSession,
  updateTimeLimitForVoiceAnswerSec,
  updateUploadResource,
  updateUserInfo,
  updateUserLocalFinalized,
} from "./store/slice";

import {
  getConnectSchema,
  getProjectSchema,
  getSaveUserInfoSchema,
  getSubmitUserResponseSchema,
  getUserSchema,
} from "./api/graphql";
import { getClient } from "./api/client";

import {
  GetUserPromptResponse,
  SaveUserInfoSchema,
  UserInfoResponse,
  getProjectInfoResponse,
} from "./@types/quiz";

import Loading from "./components/Layout/Loading";
import Header from "./components/Layout/Header";
import Layout404 from "./components/Layout404/Layout404";
import LayoutOnboarding, {
  ONBOARDING_SCREENS,
} from "./components/Onboarding/LayoutOnboarding";
import LayoutQuiz from "./components/Quiz/LayoutQuiz";
import LayoutThank from "./components/Thank/LayoutThank";
import Help from "./components/Layout/Help";
import catchAsyncError from "./api/catchError";

function App() {
  const dispatch = useDispatch();
  const { sessionId, uploadResource, contextId, mode } = useSelector(getState);
  const [showHelp, setShowHelp] = useState(false);

  const submitUserAnswer = async (): Promise<void> => {
    // Fetch
    const mutationString =
      mode === "VOICE"
        ? `{sessionId: "${sessionId}", voiceResponseUploadKey: "${uploadResource.key}", contextId: "${contextId}"}`
        : `{sessionId: "${sessionId}", contextId: "${contextId}", textResponse: "OK"}`;

    try {
      const graphql = getSubmitUserResponseSchema(mutationString);
      const client = await getClient({});
      const res = await client.post("", {
        query: graphql,
      });
      if (!res.data.data) {
        dispatch(updateGlobalError(res.data.errors[0].message));
        // Replace with Local Error Popup?
      }
      const responseData: GetUserPromptResponse =
        res.data.data.submitUserResponse;

      // Set Question
      dispatch(updateQuestion(responseData.prompt));

      // Set Mode
      dispatch(updateMode(responseData.mode));

      // Set Progress
      dispatch(
        updateProgressStepsCurrent(
          responseData.sessionMetadata.currentPromptCount
        )
      );
      dispatch(
        updateProgressStepsTotal(responseData.sessionMetadata.totalPrompts)
      );

      // Set Time Limit
      dispatch(
        updateTimeLimitForVoiceAnswerSec(
          responseData.timeLimitForVoiceAnswerSec
            ? +responseData.timeLimitForVoiceAnswerSec
            : 300
        )
      );

      // Set Context Id
      dispatch(updateContextId(responseData.contextId));

      // Set Upload Resource
      if (responseData.uploadResource) {
        dispatch(updateUploadResource(responseData.uploadResource));
      } else {
        dispatch(
          updateUploadResource({
            key: "",
            url: "",
          })
        );
      }

      if (responseData.mode === "NOTICE") {
        dispatch(updateAppLayout("thank"));
        return;
      }

      dispatch(updateAppLayout("quiz"));
    } catch (error) {
      const errorMessage = catchAsyncError(error);
      dispatch(updateGlobalError(errorMessage));
    }
  };

  const parseUserInfoResponse = (
    responseData: UserInfoResponse,
    querySessionId: string = ""
  ) => {
    dispatch(updateUserInfo(responseData));

    // SET LOCAL FINALIZED
    let currentUserLocalFinalized = responseData.finalized;
    // Validate Form Data
    if (!currentUserLocalFinalized) {
      if (
        responseData.firstName.length > 0 &&
        responseData.lastName.length > 0 &&
        responseData.email.length > 0 &&
        responseData.positions &&
        responseData.positions.length > 0 &&
        responseData.positions[0].company.length > 0 &&
        responseData.positions[0].startDate.length > 0 &&
        (responseData.positions[0].endDate === null ||
          (responseData.positions[0].endDate &&
            responseData.positions[0].endDate.length > 0))
      ) {
        currentUserLocalFinalized = true;
      }
    }
    dispatch(updateUserLocalFinalized(currentUserLocalFinalized));

    if (currentUserLocalFinalized) {
      getUserPrompt(querySessionId);
    } else {
      // onboarding
      const cookieOnboardingScreenIndex = Cookies.get("onboardingScreenIndex");
      if (cookieOnboardingScreenIndex !== undefined) {
        dispatch(updateOnboardingScreenIndex(+cookieOnboardingScreenIndex));
      }
      dispatch(updateAppLayout("onboarding"));
      // Cookies.set("appLayout", "onboarding");
      // Cookies.set(
      //   "onboardingScreenIndex",
      //   ONBOARDING_SCREENS.JOB_HISTORY.toString()
      // );
      // dispatch(updateOnboardingScreenIndex(ONBOARDING_SCREENS.JOB_HISTORY));
      // dispatch(updateAppLayout("onboarding"));
    }
  };

  const submitUserInfo = async (user: SaveUserInfoSchema) => {
    if (sessionId) {
      //dispatch(updateLoadingMessage("Saving Your Data"));
      try {
        const graphql = getSaveUserInfoSchema(sessionId, user);
        const client = await getClient({});
        const res = await client.post("", {
          query: graphql,
        });
        if (!res.data.data) {
          const errorMessage =
            res.data.errors.length > 0 && res.data.errors[0].message
              ? res.data.errors[0].message
              : "Unable to save your data";
          dispatch(updateGlobalError(errorMessage));
          return;
        }

        const responseData: UserInfoResponse =
          res.data.data.saveUserInfo.userInfo;

        parseUserInfoResponse(responseData);
      } catch (error) {
        const errorMessage = catchAsyncError(error);
        dispatch(updateGlobalError(errorMessage));
      }
    }
  };

  const getUserPrompt = async (querySessionId: string = "") => {
    const connectSessionId = sessionId || querySessionId;

    if (!connectSessionId) {
      dispatch(updateGlobalError("No Session ID is provided"));
    }

    try {
      const graphql = getConnectSchema(connectSessionId);
      const client = await getClient({});
      const res = await client.post("", {
        query: graphql,
      });
      if (!res.data.data) {
        const errorMessage =
          res.data.errors.length > 0 && res.data.errors[0].message
            ? res.data.errors[0].message
            : "Unable to connect to the server";
        dispatch(updateGlobalError(errorMessage));
        return;
      }
      const responseData: GetUserPromptResponse = res.data.data.getUserPrompt;

      // Set Question
      dispatch(updateQuestion(responseData.prompt));

      // Set Mode
      dispatch(updateMode(responseData.mode));

      // Set Progress
      dispatch(
        updateProgressStepsCurrent(
          responseData.sessionMetadata.currentPromptCount
        )
      );
      dispatch(
        updateProgressStepsTotal(responseData.sessionMetadata.totalPrompts)
      );

      // Set Time Limit
      dispatch(
        updateTimeLimitForVoiceAnswerSec(
          responseData.timeLimitForVoiceAnswerSec
            ? +responseData.timeLimitForVoiceAnswerSec
            : 300
        )
      );

      // Set Context Id
      dispatch(updateContextId(responseData.contextId));

      // Set Upload Resource
      if (responseData.uploadResource) {
        dispatch(updateUploadResource(responseData.uploadResource));
      } else {
        dispatch(
          updateUploadResource({
            key: "",
            url: "",
          })
        );
      }

      // if quiz is in finished
      if (responseData.mode === "NOTICE") {
        dispatch(updateAppLayout("thank"));
        return;
      }

      // if quiz is in progress
      if (responseData.sessionMetadata.currentPromptCount > 1) {
        dispatch(updateAppLayout("quiz"));
        return;
      }

      // try to get last viewed screen by cookie
      const cookiesSessionId = Cookies.get("sessionId");
      // Check if it is the same sessionId
      if (connectSessionId === cookiesSessionId) {
        const cookieAppLayout = Cookies.get("appLayout");
        if (cookieAppLayout === "quiz") {
          // TODOO: build quiz screen
          dispatch(updateAppLayout("quiz"));
        } else {
          // onboarding
          const cookieOnboardingScreenIndex = Cookies.get(
            "onboardingScreenIndex"
          );
          if (
            cookieOnboardingScreenIndex !== undefined &&
            +cookieOnboardingScreenIndex >= ONBOARDING_SCREENS.JOB_HISTORY
          ) {
            dispatch(updateOnboardingScreenIndex(+cookieOnboardingScreenIndex));
          }
          // else {
          //   Cookies.set(
          //     "onboardingScreenIndex",
          //     ONBOARDING_SCREENS.JOB_HISTORY.toString()
          //   );
          //   dispatch(
          //     updateOnboardingScreenIndex(ONBOARDING_SCREENS.JOB_HISTORY)
          //   );
          // }
          dispatch(updateAppLayout("onboarding"));
        }
      } else {
        Cookies.set("appLayout", "");
        Cookies.set("onboardingScreenIndex", "");
        dispatch(updateAppLayout("onboarding"));
      }
    } catch (error) {
      const errorMessage = catchAsyncError(error);
      dispatch(updateGlobalError(errorMessage));
    }
  };

  const getProjectData = async (queryProjectId: string) => {
    if (!queryProjectId) return;

    try {
      const graphql = getProjectSchema(queryProjectId);
      const client = await getClient({});
      const res = await client.post("", {
        query: graphql,
      });
      if (!res.data.data) {
        const errorMessage =
          res.data.errors.length > 0 && res.data.errors[0].message
            ? res.data.errors[0].message
            : "Unable to get Project info";
        dispatch(updateGlobalError(errorMessage));
        return;
      }
      const responseData: getProjectInfoResponse = res.data.data.getProjectInfo;

      // Set Resume Upload Resource
      if (responseData.resumeUploadResource) {
        dispatch(updateResumeUploadResource(responseData.resumeUploadResource));
      } else {
        dispatch(
          updateResumeUploadResource({
            key: "",
            url: "",
          })
        );
      }
    } catch (error) {
      const errorMessage = catchAsyncError(error);
      dispatch(updateGlobalError(errorMessage));
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const querySessionId = urlParams.get("sessionId");
    const queryProjectId = urlParams.get("projectId");
    dispatch(updateLoadingMessage("Initializing App"));

    const getProjectInfo = async (queryProjectId: string) => {
      if (!queryProjectId) return;

      try {
        const graphql = getProjectSchema(queryProjectId);
        const client = await getClient({});
        const res = await client.post("", {
          query: graphql,
        });
        if (!res.data.data) {
          const errorMessage =
            res.data.errors.length > 0 && res.data.errors[0].message
              ? res.data.errors[0].message
              : "Unable to get Project info";
          dispatch(updateGlobalError(errorMessage));
          return;
        }
        const responseData: getProjectInfoResponse =
          res.data.data.getProjectInfo;

        // Set Resume Upload Resource
        if (responseData.resumeUploadResource) {
          dispatch(
            updateResumeUploadResource(responseData.resumeUploadResource)
          );
        } else {
          dispatch(
            updateResumeUploadResource({
              key: "",
              url: "",
            })
          );
        }

        // try to get last viewed screen by cookie
        const cookiesProjectId = Cookies.get("projectId");
        // Check if it is the same sessionId
        if (queryProjectId === cookiesProjectId) {
          // onboarding
          const cookieOnboardingScreenIndex = Cookies.get(
            "onboardingScreenIndex"
          );
          if (
            cookieOnboardingScreenIndex !== undefined &&
            +cookieOnboardingScreenIndex <= ONBOARDING_SCREENS.UPLOAD_CV
          ) {
            dispatch(updateOnboardingScreenIndex(+cookieOnboardingScreenIndex));
          }
        } else {
          Cookies.set("appLayout", "");
          Cookies.set("onboardingScreenIndex", "");
        }
        dispatch(updateAppLayout("onboarding"));
      } catch (error) {
        const errorMessage = catchAsyncError(error);
        dispatch(updateGlobalError(errorMessage));
      }
    };

    const getUserInfo = async (querySessionId: string) => {
      if (!querySessionId) return;

      try {
        const graphql = getUserSchema(querySessionId);
        const client = await getClient({});
        const res = await client.post("", {
          query: graphql,
        });
        if (!res.data.data) {
          const errorMessage =
            res.data.errors.length > 0 && res.data.errors[0].message
              ? res.data.errors[0].message
              : "Unable to get User info";
          dispatch(updateGlobalError(errorMessage));
          return;
        }
        const responseData: UserInfoResponse = res.data.data.getUserInfo;

        parseUserInfoResponse(responseData, querySessionId);
      } catch (error) {
        const errorMessage = catchAsyncError(error);
        dispatch(updateGlobalError(errorMessage));
      }
    };

    if (querySessionId) {
      if (queryProjectId) {
        dispatch(updateProject(queryProjectId));
        Cookies.set("projectId", queryProjectId);
      }
      dispatch(updateSession(querySessionId));
      Cookies.set("sessionId", querySessionId);
      getUserInfo(querySessionId);
    } else {
      if (queryProjectId) {
        dispatch(updateProject(queryProjectId));
        Cookies.set("projectId", queryProjectId);
        getProjectInfo(queryProjectId);
      } else {
        dispatch(updateGlobalError("Project ID is required"));
      }
    }
  }, [dispatch]);

  return (
    <div>
      <Loading />
      <Header setShowHelp={setShowHelp} />
      <Layout404 />
      <LayoutOnboarding
        submitUserInfo={submitUserInfo}
        getUserPrompt={getUserPrompt}
        getProjectData={getProjectData}
      />
      <LayoutQuiz submitHandler={submitUserAnswer} />
      <LayoutThank />
      <Help show={showHelp} setShowHelp={setShowHelp} />
    </div>
  );
}

export default App;
