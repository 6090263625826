import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getState } from "../../store/slice";

import { SaveUserInfoSchema, UserPositionResponse } from "../../@types/quiz";

import { convertDate } from "../../utils/date";

import WarnIcon from "../Layout/WarnIcon";
import JobForm from "./JobForm";

import leftClasses from "./OnboardingLeft.module.css";
import classes from "./OnboardingJobHistory.module.css";

interface Props {
  isActive: boolean;
  isShown: boolean;
  submitUserInfo: (user: SaveUserInfoSchema) => Promise<void>;
}

const OnboardingJobHistory: FC<Props> = ({
  isActive,
  isShown,
  submitUserInfo,
}) => {
  const { userInfo } = useSelector(getState);
  const { firstName, lastName, email, positions } = userInfo;
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fullUserName =
    `${firstName} ${lastName}`.trim().length > 0
      ? `${firstName} ${lastName}`.trim()
      : "Full Name";

  const editFormHandler = () => {
    setShowPopup(true);
  };

  let emptyPositions = [];
  for (let i = positions.length; i < 3; i++) {
    emptyPositions.push(i);
  }

  useEffect(() => {
    setIsLoading(false);
  }, [userInfo]);

  return (
    <div
      className={`${leftClasses.screenLeftContent} ${
        isActive ? classes.active : ""
      } ${isShown ? classes.shown : ""}`}
    >
      <div className={`${classes.form}`}>
        <div
          className={`${classes.content} ${isLoading ? classes.loading : ""}`}
        >
          <div className={classes.loadingState}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <g>
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#5186ef"
                  strokeWidth="10"
                  r="35"
                  strokeDasharray="164.93361431346415 56.97787143782138"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  ></animateTransform>
                </circle>
                <g></g>
              </g>
            </svg>
          </div>
          <div className={classes.user}>
            {(!firstName || !lastName || !email) && (
              <div className={classes.warn}>
                <WarnIcon />
              </div>
            )}
            <div className={classes.userInfo}>
              <div className={classes.userName}>{fullUserName}</div>
              <div className={classes.userEmail}>{email}</div>
            </div>
            <button onClick={editFormHandler}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path d="M13.8067 4.69305C14.0667 4.43305 14.0667 3.99971 13.8067 3.75305L12.2467 2.19305C12 1.93305 11.5667 1.93305 11.3067 2.19305L10.08 3.41305L12.58 5.91305M2 11.4997V13.9997H4.5L11.8733 6.61971L9.37333 4.11971L2 11.4997Z" />
              </svg>
            </button>
          </div>
          <div className={classes.jobs}>
            {positions.map((position: UserPositionResponse, index) => (
              <button
                onClick={editFormHandler}
                key={`position-${index}`}
                className={classes.job}
              >
                {index === 0 &&
                  (!position.company ||
                    !position.title ||
                    !position.startDate ||
                    (position.endDate !== null && !position.endDate)) && (
                    <div className={classes.warn}>
                      <WarnIcon />
                    </div>
                  )}
                <div className={classes.jobInfo}>
                  <div className={classes.jobCompany}>
                    {position.company || "Company Name"}
                  </div>
                  {position.startDate &&
                    (position.endDate === null || position.endDate) && (
                      <div className={classes.jobPeriod}>{`${convertDate(
                        position.startDate
                      )} — ${convertDate(position.endDate)}`}</div>
                    )}
                </div>
              </button>
            ))}
            {emptyPositions.map((position, index) => (
              <button
                onClick={editFormHandler}
                key={`position-${index}`}
                className={classes.job}
              >
                {position === 0 && (
                  <div className={classes.warn}>
                    <WarnIcon />
                  </div>
                )}
                {position > 0 && (
                  <div className={classes.warn}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <circle cx={12} cy={12} r={10} fill="#5186ef"></circle>
                      <path d="M12 6l0 12" stroke="#fff" strokeWidth={1} />
                      <path d="M6 12l12 0" stroke="#fff" strokeWidth={1} />
                    </svg>
                  </div>
                )}
                <div className={classes.jobInfo}>
                  <div className={classes.jobCompany}>Company Name</div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <JobForm
        show={showPopup}
        setShowPopup={setShowPopup}
        submitUserInfo={submitUserInfo}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

export default OnboardingJobHistory;
