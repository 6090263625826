import { FC } from "react";
import parse from "html-react-parser";

import classes from "./OnboardingRight.module.css";

interface Props {
  isActive: boolean;
  isShown: boolean;
  title: string;
  text: string;
}

const OnboardingRight: FC<Props> = ({ isActive, isShown, title, text }) => {
  return (
    <div
      className={`${classes.screenRightContent} ${
        isActive ? classes.active : ""
      } ${isShown ? classes.shown : ""}`}
    >
      <h3>{title}</h3>
      <div className={classes.screenText}>{parse(text)}</div>
    </div>
  );
};

export default OnboardingRight;
