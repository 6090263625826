import { FC } from "react";

import classes from "./Header.module.css";

interface Props {
  setShowHelp(arg0: boolean): void;
}

const Header: FC<Props> = ({ setShowHelp }) => {
  const helpClickHandler = () => {
    setShowHelp(true);
  };
  return (
    <header className={classes.header}>
      <div className="container">
        <div className={classes.content}>
          <svg
            viewBox="0 0 652 168"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M84 69L43 91.5L43 121.5L84 144L84 114V69Z"
              fill="#282E46"
            />
            <path
              d="M125 89.4433V46.5L84 114L125 89.4433Z"
              fill="url(#paint0_linear_199_245)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M125 46.5L84 69V114L125 46.5Z"
              fill="#5186EF"
            />
            <path d="M125 46.5L84 24L43 46.5L84 69L125 46.5Z" fill="#8CB3FF" />
            <path
              d="M194.717 62.3038C198.917 62.3038 202.726 63.2817 206.143 65.2375C209.615 67.1374 212.332 69.8475 214.292 73.3679C216.253 76.8324 217.233 80.8836 217.233 85.5215C217.233 90.1595 216.253 94.2387 214.292 97.759C212.332 101.224 209.615 103.934 206.143 105.889C202.726 107.789 198.917 108.739 194.717 108.739C188.948 108.739 184.411 106.923 181.106 103.291V124.329H168V62.9744H180.518V68.1711C183.767 64.2596 188.5 62.3038 194.717 62.3038ZM192.448 98.0105C195.809 98.0105 198.553 96.8929 200.682 94.6577C202.866 92.3667 203.958 89.3213 203.958 85.5215C203.958 81.7218 202.866 78.7043 200.682 76.4692C198.553 74.1781 195.809 73.0326 192.448 73.0326C189.088 73.0326 186.315 74.1781 184.131 76.4692C182.002 78.7043 180.938 81.7218 180.938 85.5215C180.938 89.3213 182.002 92.3667 184.131 94.6577C186.315 96.8929 189.088 98.0105 192.448 98.0105Z"
              fill="#282E46"
            />
            <path
              d="M238.525 68.9255C240.093 66.7462 242.193 65.0978 244.826 63.9802C247.514 62.8626 250.595 62.3038 254.067 62.3038V74.3737C252.611 74.2619 251.631 74.2061 251.127 74.2061C247.374 74.2061 244.434 75.2678 242.305 77.3912C240.177 79.4587 239.113 82.5879 239.113 86.7788V108.069H226.006V62.9744H238.525V68.9255Z"
              fill="#282E46"
            />
            <path
              d="M307.322 62.9744V108.069H294.804V102.872C291.556 106.783 286.851 108.739 280.69 108.739C276.433 108.739 272.568 107.789 269.096 105.889C265.679 103.99 262.99 101.279 261.03 97.759C259.07 94.2387 258.09 90.1595 258.09 85.5215C258.09 80.8836 259.07 76.8044 261.03 73.284C262.99 69.7637 265.679 67.0535 269.096 65.1537C272.568 63.2538 276.433 62.3038 280.69 62.3038C286.459 62.3038 290.967 64.1199 294.216 67.752V62.9744H307.322ZM282.958 98.0105C286.263 98.0105 289.007 96.8929 291.191 94.6577C293.376 92.3667 294.468 89.3213 294.468 85.5215C294.468 81.7218 293.376 78.7043 291.191 76.4692C289.007 74.1781 286.263 73.0326 282.958 73.0326C279.597 73.0326 276.825 74.1781 274.641 76.4692C272.456 78.7043 271.364 81.7218 271.364 85.5215C271.364 89.3213 272.456 92.3667 274.641 94.6577C276.825 96.8929 279.597 98.0105 282.958 98.0105Z"
              fill="#282E46"
            />
            <path
              d="M366.085 62.9744V100.525C366.085 108.795 363.928 114.942 359.616 118.965C355.303 122.988 349.002 125 340.712 125C336.344 125 332.199 124.469 328.278 123.407C324.357 122.346 321.109 120.809 318.532 118.797L323.741 109.41C325.646 110.974 328.054 112.204 330.967 113.098C333.879 114.048 336.792 114.523 339.704 114.523C344.241 114.523 347.574 113.489 349.702 111.421C351.886 109.41 352.979 106.336 352.979 102.201V100.274C349.562 104.017 344.801 105.889 338.696 105.889C334.551 105.889 330.743 104.995 327.27 103.207C323.853 101.363 321.137 98.7928 319.121 95.4959C317.104 92.1991 316.096 88.3993 316.096 84.0966C316.096 79.7939 317.104 75.9942 319.121 72.6973C321.137 69.4005 323.853 66.858 327.27 65.0698C330.743 63.2258 334.551 62.3038 338.696 62.3038C345.249 62.3038 350.234 64.4552 353.651 68.7579V62.9744H366.085ZM341.3 95.1606C344.773 95.1606 347.602 94.1548 349.786 92.1432C352.026 90.0757 353.147 87.3935 353.147 84.0966C353.147 80.7998 352.026 78.1455 349.786 76.1339C347.602 74.0664 344.773 73.0326 341.3 73.0326C337.828 73.0326 334.971 74.0664 332.731 76.1339C330.491 78.1455 329.37 80.7998 329.37 84.0966C329.37 87.3935 330.491 90.0757 332.731 92.1432C334.971 94.1548 337.828 95.1606 341.3 95.1606Z"
              fill="#282E46"
            />
            <path
              d="M436.17 62.3038C441.827 62.3038 446.308 63.9802 449.612 67.3329C452.973 70.6298 454.653 75.603 454.653 82.2526V108.069H441.547V84.2643C441.547 80.688 440.791 78.0338 439.278 76.3015C437.822 74.5134 435.722 73.6193 432.977 73.6193C429.897 73.6193 427.46 74.6251 425.668 76.6368C423.876 78.5925 422.979 81.5262 422.979 85.4377V108.069H409.873V84.2643C409.873 77.1676 407.017 73.6193 401.304 73.6193C398.279 73.6193 395.871 74.6251 394.078 76.6368C392.286 78.5925 391.39 81.5262 391.39 85.4377V108.069H378.283V62.9744H390.802V68.1711C392.482 66.2712 394.526 64.8184 396.935 63.8126C399.399 62.8068 402.088 62.3038 405 62.3038C408.193 62.3038 411.077 62.9464 413.654 64.2317C416.23 65.461 418.303 67.2771 419.871 69.6799C421.719 67.3329 424.044 65.5169 426.844 64.2317C429.701 62.9464 432.809 62.3038 436.17 62.3038Z"
              fill="#282E46"
            />
            <path
              d="M512.765 62.9744V108.069H500.247V102.872C496.998 106.783 492.293 108.739 486.132 108.739C481.876 108.739 478.011 107.789 474.538 105.889C471.122 103.99 468.433 101.279 466.473 97.759C464.512 94.2387 463.532 90.1595 463.532 85.5215C463.532 80.8836 464.512 76.8044 466.473 73.284C468.433 69.7637 471.122 67.0535 474.538 65.1537C478.011 63.2538 481.876 62.3038 486.132 62.3038C491.901 62.3038 496.41 64.1199 499.659 67.752V62.9744H512.765ZM488.401 98.0105C491.705 98.0105 494.45 96.8929 496.634 94.6577C498.819 92.3667 499.911 89.3213 499.911 85.5215C499.911 81.7218 498.819 78.7043 496.634 76.4692C494.45 74.1781 491.705 73.0326 488.401 73.0326C485.04 73.0326 482.268 74.1781 480.083 76.4692C477.899 78.7043 476.807 81.7218 476.807 85.5215C476.807 89.3213 477.899 92.3667 480.083 94.6577C482.268 96.8929 485.04 98.0105 488.401 98.0105Z"
              fill="#282E46"
            />
            <path
              d="M552.12 105.889C550.832 106.839 549.236 107.566 547.331 108.069C545.483 108.516 543.551 108.739 541.534 108.739C536.101 108.739 531.929 107.37 529.016 104.632C526.104 101.894 524.647 97.8708 524.647 92.5623V53H537.754V63.9802H548.928V74.0384H537.754V92.3946C537.754 94.2945 538.23 95.7753 539.182 96.837C540.134 97.8428 541.506 98.3457 543.299 98.3457C545.315 98.3457 547.107 97.787 548.676 96.6694L552.12 105.889Z"
              fill="#282E46"
            />
            <path
              d="M604 62.9744V108.069H591.482V102.872C588.233 106.783 583.528 108.739 577.367 108.739C573.111 108.739 569.246 107.789 565.773 105.889C562.357 103.99 559.668 101.279 557.708 97.759C555.747 94.2387 554.767 90.1595 554.767 85.5215C554.767 80.8836 555.747 76.8044 557.708 73.284C559.668 69.7637 562.357 67.0535 565.773 65.1537C569.246 63.2538 573.111 62.3038 577.367 62.3038C583.136 62.3038 587.645 64.1199 590.894 67.752V62.9744H604ZM579.636 98.0105C582.94 98.0105 585.685 96.8929 587.869 94.6577C590.054 92.3667 591.146 89.3213 591.146 85.5215C591.146 81.7218 590.054 78.7043 587.869 76.4692C585.685 74.1781 582.94 73.0326 579.636 73.0326C576.275 73.0326 573.503 74.1781 571.318 76.4692C569.134 78.7043 568.042 81.7218 568.042 85.5215C568.042 89.3213 569.134 92.3667 571.318 94.6577C573.503 96.8929 576.275 98.0105 579.636 98.0105Z"
              fill="#282E46"
            />
            <defs>
              <linearGradient
                id="paint0_linear_199_245"
                x1="127.073"
                y1="46.1102"
                x2="95.6136"
                y2="115.577"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#CCDDFF" />
                <stop offset="1" stopColor="#5186EF" />
              </linearGradient>
            </defs>
          </svg>

          <button onClick={helpClickHandler}>
            <svg viewBox="0 0 41 41" fill="none">
              <path
                d="M21.8683 24.2778H18.6811V22.5558C18.6811 21.8349 18.7618 21.301 18.9232 20.9539C19.1115 20.5802 19.5149 20.0863 20.1335 19.4722L22.1911 17.4299C22.6214 16.976 22.8366 16.3887 22.8366 15.6678C22.8366 14.9737 22.608 14.3997 22.1507 13.9458C21.7204 13.492 21.1421 13.2651 20.4159 13.2651C19.6897 13.2651 19.0846 13.492 18.6004 13.9458C18.1163 14.373 17.8473 14.947 17.7935 15.6678H14.3643C14.5525 14.0126 15.198 12.7178 16.3008 11.7833C17.4304 10.8222 18.8425 10.3417 20.537 10.3417C22.2314 10.3417 23.6031 10.8089 24.6521 11.7433C25.7011 12.651 26.2255 13.9058 26.2255 15.5076C26.2255 16.6022 25.9028 17.5366 25.2573 18.3109C24.8538 18.8448 24.3428 19.3921 23.7242 19.9528C23.0787 20.5401 22.6349 20.9806 22.3928 21.2743C22.0432 21.7549 21.8683 22.3422 21.8683 23.0363V24.2778ZM18.1566 28.4426C18.1566 27.8819 18.3718 27.4014 18.8022 27.0009C19.2325 26.5738 19.7435 26.3602 20.3352 26.3602C20.927 26.3602 21.438 26.5604 21.8683 26.9609C22.2987 27.3613 22.5138 27.8419 22.5138 28.4025C22.5138 28.9899 22.2987 29.4971 21.8683 29.9243C21.4649 30.3247 20.9673 30.525 20.3756 30.525C19.7839 30.525 19.2594 30.3247 18.8022 29.9243C18.3718 29.4971 18.1566 29.0032 18.1566 28.4426Z"
                strokeWidth="0.5"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.3906 3.64554C11.1491 3.64554 3.65091 11.158 3.65091 20.4331C3.65091 29.7081 11.1491 37.2206 20.3906 37.2206C29.6322 37.2206 37.1303 29.7081 37.1303 20.4331C37.1303 11.158 29.6322 3.64554 20.3906 3.64554ZM0.78125 20.4331C0.78125 9.58027 9.55711 0.775879 20.3906 0.775879C31.2241 0.775879 40 9.58027 40 20.4331C40 31.2859 31.2241 40.0903 20.3906 40.0903C9.55711 40.0903 0.78125 31.2859 0.78125 20.4331Z"
                strokeWidth="0.8"
              />
            </svg>
            <span>Need Help?</span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
