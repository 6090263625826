// Forked Local Version from
// https://github.com/sarafhbk/react-audio-recorder/

import { useState, useRef } from "react";
import toWav from "audiobuffer-to-wav";
import { RECORD_STATUS } from "./status";
import useTimer from "./useTimer";

export type record_status_type =
  | RECORD_STATUS.RECORDING
  | RECORD_STATUS.PAUSED
  | RECORD_STATUS.IDLE;

let mediaRecorder: MediaRecorder;
let localStream: MediaStream;

// Check and select supported MimeType
// IOS issue fix
const mimeType = MediaRecorder.isTypeSupported("audio/webm;codecs=opus")
  ? "audio/webm;codecs=opus"
  : MediaRecorder.isTypeSupported("audio/webm;codecs=pcm")
  ? "audio/webm;codecs=pcm"
  : MediaRecorder.isTypeSupported("audio/webm")
  ? "audio/webm"
  : "audio/mpeg";

export const useAudioRecorder = () => {
  const dataArray = useRef<Array<Blob>>([]);

  const [status, setStatus] = useState<record_status_type>(RECORD_STATUS.IDLE);
  const [audioResult, setAudioResult] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [audioBlob, setAudioBlob] = useState<Blob>(new Blob());

  const {
    timer,
    handleStartTimer,
    handlePauseTimer,
    handleResumeTimer,
    handleResetTimer,
  } = useTimer();

  const startRecording = () => {
    if (status === RECORD_STATUS.IDLE) {
      try {
        setErrorMessage("");
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((mediaStreamObj: MediaStream) => {
            // Initializing Media Stream Object
            localStream = mediaStreamObj;
            if (MediaRecorder.isTypeSupported(mimeType)) {
              mediaRecorder = new MediaRecorder(mediaStreamObj, {
                mimeType: mimeType,
              });
            } else {
              mediaRecorder = new MediaRecorder(mediaStreamObj);
            }

            mediaRecorder.start();

            // DEPRECATED
            // Create new AudioContext and connect it to Analyser
            // It allows to animate audio wave in ReactSiriWave component
            // const audioContext = new AudioContext();
            // setAnalyser(audioContext.createAnalyser());
            // setAudioSource(
            //   audioContext.createMediaStreamSource(mediaStreamObj)
            // );

            mediaRecorder.onstart = () => {
              handleStartTimer();
              setStatus(RECORD_STATUS.RECORDING);
            };

            // Pushing Audio data to DataArray
            mediaRecorder.ondataavailable = (event: BlobEvent) => {
              dataArray.current.push(event.data);
            };
          })
          .catch((error) => {
            setErrorMessage(error?.message);
          });
      } catch (error: any) {
        setErrorMessage(error?.message);
      }
    } else {
      return;
    }
  };

  const resumeRecording = () => {
    if (status === RECORD_STATUS.PAUSED) {
      mediaRecorder.resume();
      mediaRecorder.onresume = () => {
        handleResumeTimer();
        setStatus(RECORD_STATUS.RECORDING);
      };
    } else {
      return;
    }
  };

  const pauseRecording = () => {
    if (status === RECORD_STATUS.RECORDING) {
      mediaRecorder.pause();
      mediaRecorder.onpause = () => {
        handlePauseTimer();
        setStatus(RECORD_STATUS.PAUSED);
      };
    } else {
      return;
    }
  };

  // async function to convert Audio Blob to readable WAV file
  async function convertBlobToWav(blob: Blob[]): Promise<Blob> {
    // Read Blob as file with FileReader
    const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as ArrayBuffer);
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(blob[0]);
    });

    // Getting AudioContext and decoding ArrayBuffer
    const audioContext = new AudioContext();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Using NPM Library: https://www.npmjs.com/package/audiobuffer-to-wav
    // to convert decoded audioBuffer to WAV
    const wavBuffer = toWav(audioBuffer);

    // Returning new Blob as audio/wav file
    return new Blob([new DataView(wavBuffer)], { type: "audio/wav" });
  }

  const stopRecording = () => {
    if (status !== RECORD_STATUS.IDLE) {
      mediaRecorder.stop();
      mediaRecorder.onstop = async () => {
        handleResetTimer();

        // Convert Audio DataArray to WAV
        let audioData = await convertBlobToWav(dataArray.current);

        // Change state for audioBlob to upload it to Amazon
        setAudioBlob(audioData);
        dataArray.current = [];
        const audioResult = window.URL.createObjectURL(audioData);
        setAudioResult(audioResult);
        setStatus(RECORD_STATUS.IDLE);

        // stop recording local voice
        localStream.getAudioTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      };
    } else {
      return;
    }
  };

  return {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    audioBlob,
    errorMessage,
    timer,
    mediaRecorder,
    dataArray,
    audioResult,
  };
};
