import { FC } from "react";

const WarnIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 15C11.7348 15 11.4804 15.1054 11.2929 15.2929C11.1054 15.4804 11 15.7348 11 16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16C13 15.7348 12.8946 15.4804 12.7071 15.2929C12.5196 15.1054 12.2652 15 12 15ZM12 6C11.7551 6.00003 11.5187 6.08996 11.3356 6.25272C11.1526 6.41547 11.0357 6.63975 11.007 6.883L11 7V13C11.0003 13.2549 11.0979 13.5 11.2728 13.6854C11.4478 13.8707 11.687 13.9822 11.9414 13.9972C12.1958 14.0121 12.4464 13.9293 12.6418 13.7657C12.8373 13.6021 12.9629 13.3701 12.993 13.117L13 13V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6Z"
        fill="#EE3360"
      />
    </svg>
  );
};

export default WarnIcon;
