import { FC } from "react";
import { CSSTransition } from "react-transition-group";
import classes from "./Popup.module.css";
import Button from "../Layout/Button";

const animationTiming = {
  enter: 500,
  exit: 350,
};

interface Props {
  show?: boolean;
  stopRecording(): Promise<void>;
}

const TimerPopup: FC<Props> = ({ show = false, stopRecording }) => {
  const sendClickHandler = () => {
    stopRecording();
  };
  return (
    <>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.OverlayOpen,
          exit: "",
          exitActive: classes.OverlayClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.overlay}></div>
      </CSSTransition>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.ModalOpen,
          exit: "",
          exitActive: classes.ModalClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.popup}>
          <p>
            Oops!
            <br />
            That was a bit too long. The question will be sent as is. If we need
            more info, we’ll just ask a follow-up question.
          </p>
          <div className={classes.buttons}>
            <Button small label="Send" onClick={sendClickHandler} />
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default TimerPopup;
