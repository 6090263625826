import { FC } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

import { updateAppLayout } from "../../store/slice";

import classes from "./OnboardingSteps.module.css";

interface Props {
  filled?: number;
}

// Total Onboarding Steps
const onboardingScreenTotal = [0, 1, 2, 3, 4, 5, 6, 7];
const skipAppearsAfter = 4;

const OnboardingSteps: FC<Props> = ({ filled = 1 }) => {
  const dispatch = useDispatch();

  const skipButtonClickHandler = () => {
    Cookies.set("appLayout", "quiz");
    dispatch(updateAppLayout("quiz"));
  };
  return (
    <div className={classes.steps}>
      <ul>
        {onboardingScreenTotal.map((step, index) => (
          <li
            key={`onboarding-step-${index}`}
            className={`${filled > index ? classes.active : ""}`}
          ></li>
        ))}
      </ul>
      <button
        onClick={skipButtonClickHandler}
        className={`${classes.skip} ${
          filled > skipAppearsAfter ? classes.visible : ""
        }`}
      >
        Skip
      </button>
    </div>
  );
};

export default OnboardingSteps;
