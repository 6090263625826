import { FC } from "react";
import classes from "./OnboardingLeft.module.css";

interface Props {
  isActive: boolean;
  isShown: boolean;
  title: string;
  image: string;
}

const OnboardingLeft: FC<Props> = ({ isActive, isShown, title, image }) => {
  return (
    <div
      className={`${classes.screenLeftContent} ${
        isActive ? classes.active : ""
      } ${isShown ? classes.shown : ""}`}
    >
      <div className={classes.screenLeftCircle}></div>
      <img
        src={image}
        alt={title}
        title={title}
        className={classes.screenVisual}
      />
    </div>
  );
};

export default OnboardingLeft;
