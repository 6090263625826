import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import {
  getState,
  updateAppLayout,
  updateGlobalError,
  updateOnboardingScreenIndex,
  updateSession,
} from "../../store/slice";

import Button from "../Layout/Button";

import classes from "./BackNext.module.css";
import { ONBOARDING_SCREENS } from "./LayoutOnboarding";

interface Props {
  getProjectData: (projectId: string) => Promise<void>;
  backHidden?: boolean;
  nextDisabled?: boolean;
  lastScreen?: boolean;
}

const BackNext: FC<Props> = ({
  getProjectData,
  backHidden = false,
  nextDisabled = false,
  lastScreen = false,
}) => {
  const dispatch = useDispatch();
  const { onboardingScreenIndex, projectId, cv, userLocalFinalized } =
    useSelector(getState);

  const backButtonClickHandler = () => {
    // remove CV & sessionId
    if (onboardingScreenIndex === ONBOARDING_SCREENS.JOB_HISTORY) {
      if (!cv) {
        dispatch(updateSession(""));
        Cookies.set("sessionId", "");
        window.history.pushState(
          "",
          "Remote Branch",
          `?projectId=${projectId}`
        );
        if (projectId) {
          getProjectData(projectId);
        } else {
          dispatch(updateGlobalError("Project ID is required"));
        }
      }
    }

    dispatch(updateOnboardingScreenIndex(onboardingScreenIndex - 1));
    Cookies.set(
      "onboardingScreenIndex",
      (onboardingScreenIndex - 1).toString()
    );
  };
  const nextButtonClickHandler = () => {
    let onboardingTargetScreenIndex = onboardingScreenIndex + 1;
    if (
      userLocalFinalized &&
      onboardingTargetScreenIndex === ONBOARDING_SCREENS.UPLOAD_CV
    ) {
      onboardingTargetScreenIndex = ONBOARDING_SCREENS.JOB_HISTORY;
    }
    dispatch(updateOnboardingScreenIndex(onboardingTargetScreenIndex));
    Cookies.set(
      "onboardingScreenIndex",
      onboardingTargetScreenIndex.toString()
    );
  };
  const startButtonClickHandler = () => {
    Cookies.set("appLayout", "quiz");
    dispatch(updateAppLayout("quiz"));
  };
  return (
    <div className={classes.buttons}>
      <button
        onClick={backButtonClickHandler}
        className={`${classes.prev} ${backHidden ? classes.hidden : ""}`}
      >
        <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.333 15.1667V40.8334M39.083 15.1667L26.1883 28L39.083 40.8334"
            strokeWidth="4.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Back</span>
      </button>
      {lastScreen ? (
        <Button onClick={startButtonClickHandler} label="Start Assessment" />
      ) : (
        <button
          onClick={nextButtonClickHandler}
          className={`${classes.next} ${nextDisabled ? classes.disabled : ""}`}
        >
          <span>Next</span>
          <svg
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39.084 15.1667V40.8334M16.334 15.1667L29.2287 28L16.334 40.8334"
              strokeWidth="4.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default BackNext;
