import { FC } from "react";
import { CSSTransition } from "react-transition-group";
import classes from "./Popup.module.css";
import Button from "../Layout/Button";

const animationTiming = {
  enter: 500,
  exit: 350,
};

interface Props {
  show?: boolean;
  type?: "error" | "voice";
  text?: string;
  setShowPopup(arg0: boolean): void;
  resumeRecording(): void;
  stopRecording(): Promise<void>;
  retry(): void;
  uploading?: boolean;
}

const Popup: FC<Props> = ({
  show = false,
  type = "voice",
  text = "Are you ready to send your response?",
  setShowPopup,
  resumeRecording,
  stopRecording,
  retry,
  uploading = false,
}) => {
  const continueClickHandler = () => {
    setShowPopup(false);
    resumeRecording();
  };
  const sendClickHandler = () => {
    stopRecording();
  };
  const retryPressHandler = () => {
    retry();
  };
  return (
    <>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.OverlayOpen,
          exit: "",
          exitActive: classes.OverlayClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.overlay}></div>
      </CSSTransition>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.ModalOpen,
          exit: "",
          exitActive: classes.ModalClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.popup}>
          <p>{text}</p>
          <div className={classes.buttons}>
            {type === "error" && (
              <Button label="Retry" onClick={retryPressHandler} />
            )}
            {type === "voice" && (
              <>
                <Button
                  small
                  secondary
                  label="Continue Recording"
                  onClick={continueClickHandler}
                />
                <Button small label="Send" onClick={sendClickHandler} />
              </>
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Popup;
