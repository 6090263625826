import { FC } from "react";

const PDFIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5889 4.49219H36.9825L52.5717 20.7412V52.3457C52.5717 56.3048 49.3686 59.5079 45.4232 59.5079H18.5889C14.6298 59.5079 11.4268 56.3048 11.4268 52.3457V11.6544C11.4267 7.69524 14.6297 4.49219 18.5889 4.49219Z"
        fill="#E5252A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9678 4.49219V20.6175H52.5707L36.9678 4.49219Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M19.3857 45.5409V35.4918H23.6611C24.7196 35.4918 25.5582 35.7805 26.1906 36.3716C26.8229 36.949 27.1391 37.7326 27.1391 38.7086C27.1391 39.6847 26.8229 40.4683 26.1906 41.0456C25.5582 41.6368 24.7196 41.9255 23.6611 41.9255H21.9564V45.5409H19.3857ZM21.9564 39.7397H23.3724C23.7573 39.7397 24.0597 39.6572 24.266 39.4648C24.4722 39.2861 24.5822 39.0386 24.5822 38.7087C24.5822 38.3788 24.4722 38.1313 24.266 37.9526C24.0598 37.7601 23.7574 37.6777 23.3724 37.6777H21.9564V39.7397ZM28.1976 45.5409V35.4918H31.7581C32.4592 35.4918 33.1191 35.588 33.7376 35.7943C34.3562 36.0004 34.9199 36.2892 35.4148 36.6878C35.9097 37.0727 36.3083 37.5951 36.597 38.255C36.872 38.9149 37.0232 39.671 37.0232 40.5233C37.0232 41.3618 36.872 42.1179 36.597 42.7777C36.3083 43.4376 35.9097 43.96 35.4148 44.3449C34.9198 44.7435 34.3562 45.0322 33.7376 45.2385C33.1191 45.4446 32.4592 45.5409 31.7581 45.5409H28.1976ZM30.7133 43.3552H31.4556C31.8543 43.3552 32.2255 43.314 32.5691 43.2177C32.8991 43.1215 33.2153 42.9702 33.5177 42.764C33.8064 42.5579 34.0401 42.2691 34.205 41.8842C34.37 41.4993 34.4525 41.0456 34.4525 40.5233C34.4525 39.9871 34.37 39.5335 34.205 39.1486C34.0401 38.7637 33.8064 38.475 33.5177 38.2688C33.2153 38.0626 32.8991 37.9113 32.5691 37.8151C32.2255 37.7189 31.8543 37.6776 31.4556 37.6776H30.7133V43.3552ZM38.3154 45.5409V35.4918H45.4639V37.6776H40.8861V39.286H44.5428V41.4581H40.8861V45.5409H38.3154Z"
        fill="white"
      />
    </svg>
  );
};

export default PDFIcon;
