import { FC } from "react";
import { useSelector } from "react-redux";

import { getState } from "../../store/slice";

import classes from "./Layout404.module.css";

const Layout404: FC = () => {
  const { appLayout, globalError } = useSelector(getState);
  return (
    <div
      className={`layout ${classes.layout404} ${
        appLayout === "404" ? `active ${classes.active}` : ""
      }`}
    >
      <div className="container">
        <img src="/error.svg" alt="Oops, something went wrong!" />
        <h2>Oops, something went wrong!</h2>
        <p>{globalError}</p>
      </div>
    </div>
  );
};

export default Layout404;
