import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import Typewriter from "typewriter-effect";
//import { ReactTyped } from "react-typed";
// import { TypeAnimation } from "react-type-animation";
import { CSSTransition } from "react-transition-group";
import { RECORD_STATUS } from "../../react-audio-recorder/status";
import { record_status_type } from "../../react-audio-recorder/useAudioRecorder";
import { getState } from "../../store/slice";

import classes from "./Question.module.css";

const animationTiming = {
  enter: 0,
  exit: 0,
};

interface Props {
  show?: boolean;
  status: record_status_type;
  setTypewriting: React.Dispatch<React.SetStateAction<boolean>>;
}

const Question: FC<Props> = ({ show = false, status, setTypewriting }) => {
  const { mode, question } = useSelector(getState);
  const [fontSize, setFontSize] = useState("3.75vw");
  //const [typewriterSpeed, setTypewriterSpeed] = useState(25);

  useEffect(() => {
    const setQuestionFontSize = () => {
      if (window.innerWidth > window.innerHeight) {
        // landscape
        const minSize = 28;
        const maxSize = 72;
        if (question.length <= 50) {
          setFontSize(`${(maxSize / window.innerWidth) * 100}vw`);
          return;
        }
        if (question.length > 200) {
          setFontSize(`${(minSize / window.innerWidth) * 100}vw`);
          return;
        }
        const percentSize = (question.length - 50) / (200 - 50);
        const addedSize = maxSize - (72 - 28) * percentSize;
        setFontSize(`${addedSize / 19.2}vw`);
      } else {
        // portrait
        const minSize = 16;
        const maxSize = 40;
        if (question.length <= 40) {
          setFontSize(`${maxSize}px`);
          return;
        }
        if (question.length > 100) {
          setFontSize(`${minSize}px`);
          return;
        }
        const percentSize = (question.length - 40) / (100 - 40);
        const addedSize = maxSize - (40 - 16) * percentSize;
        setFontSize(`${addedSize}px`);
      }
    };

    let timeout: NodeJS.Timeout;
    if (question && show) {
      setTypewriting(true);
      timeout = setTimeout(() => {
        setTypewriting(false);
      }, 500 + question.split(" ").length * 25);
    }

    setQuestionFontSize();

    window.addEventListener("resize", setQuestionFontSize);

    return () => {
      window.removeEventListener("resize", setQuestionFontSize);
      clearTimeout(timeout);
    };
  }, [question, setTypewriting, show]);

  // useEffect(() => {
  //   setTypewriting(true);

  //   setTypewriterSpeed(
  //     question.length > 0
  //       ? Math.max(1, Math.round(600 / Math.max(40, question.length)))
  //       : 25
  //   );
  // }, [question, setTypewriting]);
  //let printTime = 0;
  return (
    <CSSTransition
      in={show}
      timeout={animationTiming}
      mountOnEnter
      unmountOnExit
    >
      <div
        className={`${classes.question} ${
          mode === "CONFIRMATION" ? classes.confirmation : ""
        } ${status === RECORD_STATUS.RECORDING ? classes.recording : ""}`}
        style={{ fontSize: fontSize }}
      >
        <p>
          {question.length > 0
            ? question.split(" ").map((item, index) => (
                <span
                  key={`question-word-${index}`}
                  style={{ animationDelay: `${500 + index * 25}ms` }}
                >
                  {item}{" "}
                </span>
              ))
            : ""}
        </p>
        {/* <TypeAnimation
          sequence={[question.length > 0 ? question : ""]}
          wrapper="span"
          speed={500}
        /> */}
        {/* <ReactTyped
          strings={[question.length > 0 ? question : ""]}
          typeSpeed={10}
          startWhenVisible
        /> */}
        {/* <Typewriter
          onInit={(typewriter) => {
            typewriter
              .changeDelay(typewriterSpeed)
              .pauseFor(500)
              .callFunction(() => {
                printTime = Date.now();
              })
              .typeString(question.length > 0 ? question : "Not loaded")
              .start()
              .callFunction(() => {
                console.log(
                  `Printed in ${(Date.now() - printTime) / 1000} sec`
                );
                setTypewriting(false);
              });
          }}
          options={{
            cursor: "",
            //delay: 25,
            //delay: 25,
          }}
        /> */}
      </div>
    </CSSTransition>
  );
};

export default Question;
