import { FC } from "react";
import { useSelector } from "react-redux";
import { record_status_type } from "../../react-audio-recorder/useAudioRecorder";
import { RECORD_STATUS } from "../../react-audio-recorder/status";

import { getState } from "../../store/slice";

import { secondsToTime } from "../../utils/date";

import classes from "./QuizFooter.module.css";

interface Props {
  active?: boolean;
  status: record_status_type;
  timer?: number;
  startClickHandler: () => void;
  pauseClickHandler: () => void;
  confirmClickHandler: () => void;
  typewriting?: boolean;
}

const QuizFooter: FC<Props> = ({
  active = false,
  status,
  timer = 0,
  startClickHandler,
  pauseClickHandler,
  confirmClickHandler,
  typewriting = true,
}) => {
  const { mode, timeLimitForVoiceAnswerSec } = useSelector(getState);
  const timerStr = secondsToTime(Math.abs(timeLimitForVoiceAnswerSec - timer));

  const arc =
    timer <= timeLimitForVoiceAnswerSec
      ? Math.min(
          0.999,
          (timeLimitForVoiceAnswerSec - timer) / timeLimitForVoiceAnswerSec
        )
      : Math.min(
          0.999,
          (timeLimitForVoiceAnswerSec - (timer % timeLimitForVoiceAnswerSec)) /
            timeLimitForVoiceAnswerSec
        );

  const askClickHandler = () => {
    console.log("askClickHandler");
  };
  const skipClickHandler = () => {
    console.log("skipClickHandler");
  };

  const micButtonClickHandler = () => {
    switch (status) {
      case RECORD_STATUS.IDLE:
        startClickHandler();
        break;
      case RECORD_STATUS.RECORDING:
        pauseClickHandler();
        break;
    }
  };
  return (
    <footer className={`${classes.footer} ${active ? classes.active : ""}`}>
      <button className={classes.button} onClick={askClickHandler}>
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.8569 37.306V35.2115C11.8569 33.5123 12.5369 31.8828 13.7473 30.6813C14.9577 29.4798 16.5993 28.8048 18.3111 28.8048H23.9585M31.9213 38.3989V38.4149M31.9213 33.5938C32.6447 33.5915 33.3463 33.3484 33.914 32.9034C34.4817 32.4584 34.8826 31.8372 35.0525 31.1393C35.2224 30.4413 35.1515 29.707 34.8512 29.0538C34.5509 28.4005 34.0386 27.8662 33.3961 27.5362C32.7542 27.2098 32.0199 27.1086 31.3127 27.2491C30.6055 27.3896 29.967 27.7634 29.501 28.3099M15.084 15.9913C15.084 17.6905 15.764 19.3201 16.9744 20.5216C18.1848 21.7231 19.8264 22.3981 21.5382 22.3981C23.2499 22.3981 24.8916 21.7231 26.1019 20.5216C27.3123 19.3201 27.9923 17.6905 27.9923 15.9913C27.9923 14.2922 27.3123 12.6626 26.1019 11.4611C24.8916 10.2596 23.2499 9.58459 21.5382 9.58459C19.8264 9.58459 18.1848 10.2596 16.9744 11.4611C15.764 12.6626 15.084 14.2922 15.084 15.9913Z"
            stroke="#BCC5D2"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span>Ask Question</span>
      </button>

      {mode === "VOICE" && (
        <div className={classes.record}>
          {(status === RECORD_STATUS.RECORDING ||
            status === RECORD_STATUS.PAUSED) &&
            timer > timeLimitForVoiceAnswerSec && (
              <div className={classes.timeNotice}>
                The optimal response time is{" "}
                {Math.ceil(timeLimitForVoiceAnswerSec / 60)}&nbsp;min. The time
                of the entire interview may increase.
              </div>
            )}
          {(status === RECORD_STATUS.RECORDING ||
            status === RECORD_STATUS.PAUSED) && (
            <div className={classes.time}>{`${
              timer > timeLimitForVoiceAnswerSec ? "-" : ""
            }${timerStr}`}</div>
          )}
          {(status === RECORD_STATUS.RECORDING ||
            status === RECORD_STATUS.PAUSED) && (
            <div
              className={`${classes.circle} ${
                timer > timeLimitForVoiceAnswerSec ? classes.red : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 176 176"
                fill="none"
              >
                <circle
                  cx="88"
                  cy="88"
                  r="88"
                  fill="none"
                  stroke="#BCC5D2"
                  strokeWidth="3"
                />
                <circle
                  className={`${classes.arc} `}
                  cx="88"
                  cy="88"
                  r="88"
                  fill="none"
                  strokeWidth="3"
                  strokeDashoffset={`calc(552.92 * ${arc})`}
                  pathLength="552.92"
                />
              </svg>
            </div>
          )}
          <button
            onClick={micButtonClickHandler}
            className={`${
              status === RECORD_STATUS.RECORDING ? classes.recording : ""
            } ${typewriting ? classes.disabled : ""}`}
          >
            {(status === RECORD_STATUS.IDLE ||
              status === RECORD_STATUS.PAUSED) && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 60 60"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 16.8849C20 11.4256 24.4772 7 30 7C35.5228 7 40 11.4256 40 16.8849V28.1151C40 33.5744 35.5228 38 30 38C24.4772 38 20 33.5744 20 28.1151V16.8849ZM30 9.33962C25.7843 9.33962 22.3669 12.7178 22.3669 16.8849V28.1151C22.3669 32.2822 25.7843 35.6604 30 35.6604C34.2157 35.6604 37.6331 32.2822 37.6331 28.1151V16.8849C37.6331 12.7178 34.2157 9.33962 30 9.33962Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.2931 28C17.0073 28 17.5862 28.5626 17.5862 29.2565C17.5862 35.9187 23.1441 41.3194 30 41.3194C36.8559 41.3194 42.4138 35.9187 42.4138 29.2565C42.4138 28.5626 42.9927 28 43.7069 28C44.4211 28 45 28.5626 45 29.2565C45 36.8833 38.972 43.1419 31.2931 43.7791V49.4869H39.569C40.2831 49.4869 40.8621 50.0495 40.8621 50.7435C40.8621 51.4374 40.2831 52 39.569 52H20.3017C19.5876 52 19.0086 51.4374 19.0086 50.7435C19.0086 50.0495 19.5876 49.4869 20.3017 49.4869H28.7069V43.7791C21.028 43.1419 15 36.8833 15 29.2565C15 28.5626 15.5789 28 16.2931 28Z"
                  fill="white"
                />
              </svg>
            )}
            {status === RECORD_STATUS.RECORDING && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 60 60"
                fill="none"
              >
                <rect
                  x="18"
                  y="18"
                  width="24"
                  height="24"
                  rx="3"
                  strokeWidth="2.5"
                />
              </svg>
            )}
          </button>
        </div>
      )}

      {mode === "CONFIRMATION" && (
        <button
          className={`${classes.confirm} ${
            typewriting ? classes.disabled : ""
          }`}
          onClick={confirmClickHandler}
        >
          OK
        </button>
      )}

      <button className={classes.button} onClick={skipClickHandler}>
        <span>Skip Question</span>
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M33.5 13V35M14 13L25.0526 24L14 35"
            strokeWidth="4.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </footer>
  );
};

export default QuizFooter;
