import { FC } from "react";
import { useSelector } from "react-redux";

import { getState } from "../../store/slice";

import classes from "./LayoutThank.module.css";

const LayoutThank: FC = () => {
  const { appLayout, question } = useSelector(getState);
  return (
    <div
      className={`layout ${classes.thank} ${
        appLayout === "thank" ? `active ${classes.active}` : ""
      }`}
    >
      <div className="container">
        <img src="/thank.svg" alt={question} />
        <h2>{question}</h2>
      </div>
    </div>
  );
};

export default LayoutThank;
