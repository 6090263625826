import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropzone, { FileRejection } from "react-dropzone";

import {
  getState,
  updateCV,
  updateGlobalError,
  updateSession,
  updateUserInfo,
  updateUserLocalFinalized,
} from "../../store/slice";
import catchAsyncError from "../../api/catchError";

import leftClasses from "./OnboardingLeft.module.css";
import classes from "./OnboardingCV.module.css";
import { getSubmitResumeResponseSchema } from "../../api/graphql";
import { getClient } from "../../api/client";
import { submitResumeResponse } from "../../@types/quiz";
import PDFIcon from "../Layout/PDFIcon";
import DOCIcon from "../Layout/DOCIcon";
import DOCXIcon from "../Layout/DOCXIcon";

interface Props {
  isActive: boolean;
  isShown: boolean;
  cvUploaded: boolean;
  setCvUploaded: React.Dispatch<React.SetStateAction<boolean>>;
  getUserPrompt: (querySessionId?: string) => Promise<void>;
}

const OnboardingCV: FC<Props> = ({
  isActive,
  isShown,
  setCvUploaded,
  getUserPrompt,
}) => {
  const dispatch = useDispatch();
  const { cv, projectId, resumeUploadResource } = useSelector(getState);
  const [parsedFile, setParsedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorText, setErrorText] = useState("");

  const submitResume = async (): Promise<void> => {
    try {
      const graphql = getSubmitResumeResponseSchema(
        projectId,
        resumeUploadResource.key
      );
      const client = await getClient({});
      const res = await client.post("", {
        query: graphql,
      });
      if (!res.data.data) {
        setIsLoading(false);
        dispatch(updateGlobalError(res.data.errors[0].message));
        // Replace with Local Error Popup?
      } else {
        const responseData: submitResumeResponse = res.data.data.submitResume;

        if (responseData.sessionId) {
          dispatch(updateSession(responseData.sessionId));
          window.history.pushState(
            "",
            "Remote Branch",
            `?projectId=${projectId}&sessionId=${responseData.sessionId}`
          );
        }

        // Store Link to CV file
        dispatch(updateCV(responseData.downloadUrl));

        // Store User Info
        dispatch(updateUserInfo(responseData.userInfo));

        // SET LOCAL FINALIZED
        let currentUserLocalFinalized = responseData.userInfo.finalized;
        // Validate Form Data
        if (!currentUserLocalFinalized) {
          if (
            responseData.userInfo.firstName && responseData.userInfo.firstName.length > 0 &&
            responseData.userInfo.lastName && responseData.userInfo.lastName.length > 0 &&
            responseData.userInfo.email && responseData.userInfo.email.length > 0 &&
            responseData.userInfo.positions &&
            responseData.userInfo.positions.length > 0 &&
            responseData.userInfo.positions[0].company.length > 0 &&
            responseData.userInfo.positions[0].startDate.length > 0 &&
            (responseData.userInfo.positions[0].endDate === null ||
              (responseData.userInfo.positions[0].endDate &&
                responseData.userInfo.positions[0].endDate.length > 0))
          ) {
            currentUserLocalFinalized = true;
          }
        }
        dispatch(updateUserLocalFinalized(currentUserLocalFinalized));
        if (currentUserLocalFinalized) {
          getUserPrompt(responseData.sessionId);
        }

        setIsLoading(false);
        setCvUploaded(true);
      }
    } catch (error) {
      const errorMessage = catchAsyncError(error);
      dispatch(updateGlobalError(errorMessage));
      setIsLoading(false);
    }
  };

  const uploadCVtoS3 = async (file: File) => {
    try {
      const response = await fetch(resumeUploadResource.url, {
        method: "PUT",
        body: file,
        redirect: "follow",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (response.ok) {
        await submitResume();
      } else {
        const errorMessage = catchAsyncError(error);
        dispatch(updateGlobalError(errorMessage));
        setIsLoading(false);
      }
    } catch (error) {
      const errorMessage = catchAsyncError(error);
      dispatch(updateGlobalError(errorMessage));
      setIsLoading(false);
    }
  };

  const cvFileDropHandler = (files: File[]) => {
    setError("");
    setErrorText("");
    if (files && files.length === 1) {
      setIsLoading(true);
      setParsedFile(files[0]);

      // upload CV file request
      uploadCVtoS3(files[0]);
    }
  };

  const cvFileDropError = (fileRejections: FileRejection[]) => {
    console.log(fileRejections[0].errors[0]);
    setError(fileRejections[0].errors[0].code);
    switch (fileRejections[0].errors[0].code) {
      case "too-many-files":
        setErrorText("Only one file can be uploaded");
        break;
      case "file-invalid-type":
        setErrorText("Not supported format");
        break;
      case "file-too-large":
        setErrorText("File size is more then 20Mb");
        break;
      default:
        setErrorText(fileRejections[0].errors[0].message);
        break;
    }
  };

  const deleteCVHandler = () => {
    setParsedFile(null);
    setCvUploaded(false);
    dispatch(updateSession(""));
    window.history.pushState("", "Remote Branch", `?projectId=${projectId}`);
  };

  return (
    <div
      className={`${leftClasses.screenLeftContent} ${
        isActive ? classes.active : ""
      } ${isShown ? classes.shown : ""}`}
    >
      <div className={classes.cv}>
        {(!parsedFile || isLoading) && (
          <div className={classes.area}>
            {!parsedFile && (
              <Dropzone
                accept={{
                  "application/pdf": [".pdf"],
                  "application/msword": [".doc"],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [".docx"],
                }}
                noClick={true}
                maxSize={20971520}
                maxFiles={1}
                onDrop={(acceptedFiles) => cvFileDropHandler(acceptedFiles)}
                onDropRejected={(fileRejections, event) =>
                  cvFileDropError(fileRejections)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div className={classes.drop} {...getRootProps()}>
                    <input
                      id="cvFile"
                      {...getInputProps()}
                      accept=".pdf,.doc,.docx"
                    />
                    <label
                      htmlFor="cvFile"
                      className={error ? classes.hasError : ""}
                    >
                      {error && (
                        <span className={`${classes.tip} ${classes.error}`}>
                          <b>{errorText}</b>
                        </span>
                      )}
                      <span className={`${classes.big} ${classes.landscape}`}>
                        Drop your CV file here, or <span>Browse</span>
                      </span>
                      <span className={`${classes.big} ${classes.portrait}`}>
                        <span>Browse</span> your CV file
                      </span>
                      <span
                        className={`${classes.tip} ${
                          error && error === "file-invalid-type"
                            ? classes.tipRed
                            : ""
                        }`}
                      >
                        Supported formats: <b>PDF, DOC, DOCX</b>
                      </span>
                      <span
                        className={`${classes.tip} ${
                          error && error === "file-too-large"
                            ? classes.tipRed
                            : ""
                        }`}
                      >
                        Max. file size: <b>20Mb</b>
                      </span>
                    </label>
                  </div>
                )}
              </Dropzone>
            )}
            {parsedFile && (
              <div className={classes.progress}>
                <div className={classes.fileInfo}>
                  <div className={classes.fileData}>
                    <div className={classes.fileIcon}>
                      {parsedFile.type === "application/pdf" && <PDFIcon />}
                      {parsedFile.type === "application/msword" && <DOCIcon />}
                      {parsedFile.type ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                        <DOCXIcon />
                      )}
                    </div>
                    <div className={classes.fileName}>{parsedFile.name}</div>
                  </div>
                  <div className={classes.fileSize}>
                    {`${(parsedFile.size / (1024 * 1024)).toFixed(2)}Mb`}
                  </div>
                </div>
                <div className={classes.fileLoadingBar}>
                  <div className={classes.fileLoadingStatus}></div>
                </div>
              </div>
            )}
          </div>
        )}
        {!isLoading && parsedFile && cv && (
          <div className={classes.fileViewer}>
            <div className={classes.fileViewerText}>
              <div className={classes.fileViewerTitle}>Success</div>
              <p>
                The file has been uploaded! You can check your CV or delete and
                upload another file!
              </p>
            </div>
            <div className={classes.fileViewerFile}>
              <div className={classes.fileViewerIcon}>
                {parsedFile.type === "application/pdf" && <PDFIcon />}
                {parsedFile.type === "application/msword" && <DOCIcon />}
                {parsedFile.type ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                  <DOCXIcon />
                )}
              </div>
              <div className={classes.fileViewerData}>
                <div className={classes.fileViewerName}>{parsedFile.name}</div>
                <div className={classes.fileViewerSize}>{`${(
                  parsedFile.size /
                  (1024 * 1024)
                ).toFixed(2)}Mb`}</div>
              </div>

              <button onClick={deleteCVHandler}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path d="M17.6 12.8H22.4C22.4 12.1635 22.1471 11.553 21.6971 11.1029C21.247 10.6529 20.6365 10.4 20 10.4C19.3635 10.4 18.753 10.6529 18.3029 11.1029C17.8529 11.553 17.6 12.1635 17.6 12.8ZM15.2 12.8C15.2 11.527 15.7057 10.3061 16.6059 9.40589C17.5061 8.50571 18.727 8 20 8C21.273 8 22.4939 8.50571 23.3941 9.40589C24.2943 10.3061 24.8 11.527 24.8 12.8H30.8C31.1183 12.8 31.4235 12.9264 31.6485 13.1515C31.8736 13.3765 32 13.6817 32 14C32 14.3183 31.8736 14.6235 31.6485 14.8485C31.4235 15.0736 31.1183 15.2 30.8 15.2H29.7416L28.6784 27.608C28.5762 28.8062 28.028 29.9223 27.1422 30.7356C26.2565 31.5489 25.0977 32.0001 23.8952 32H16.1048C14.9023 32.0001 13.7435 31.5489 12.8578 30.7356C11.972 29.9223 11.4238 28.8062 11.3216 27.608L10.2584 15.2H9.2C8.88174 15.2 8.57652 15.0736 8.35147 14.8485C8.12643 14.6235 8 14.3183 8 14C8 13.6817 8.12643 13.3765 8.35147 13.1515C8.57652 12.9264 8.88174 12.8 9.2 12.8H15.2ZM23.6 20C23.6 19.6817 23.4736 19.3765 23.2485 19.1515C23.0235 18.9264 22.7183 18.8 22.4 18.8C22.0817 18.8 21.7765 18.9264 21.5515 19.1515C21.3264 19.3765 21.2 19.6817 21.2 20V24.8C21.2 25.1183 21.3264 25.4235 21.5515 25.6485C21.7765 25.8736 22.0817 26 22.4 26C22.7183 26 23.0235 25.8736 23.2485 25.6485C23.4736 25.4235 23.6 25.1183 23.6 24.8V20ZM17.6 18.8C17.2817 18.8 16.9765 18.9264 16.7515 19.1515C16.5264 19.3765 16.4 19.6817 16.4 20V24.8C16.4 25.1183 16.5264 25.4235 16.7515 25.6485C16.9765 25.8736 17.2817 26 17.6 26C17.9183 26 18.2235 25.8736 18.4485 25.6485C18.6736 25.4235 18.8 25.1183 18.8 24.8V20C18.8 19.6817 18.6736 19.3765 18.4485 19.1515C18.2235 18.9264 17.9183 18.8 17.6 18.8Z" />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingCV;
