import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAudioRecorder } from "../../react-audio-recorder/useAudioRecorder";
import { RECORD_STATUS } from "../../react-audio-recorder/status";

import {
  getState,
  updateAppLayout,
  updateLoadingMessage,
  updateLocalError,
  updateProgressTime,
} from "../../store/slice";

import ReactSiriwave from "../../SiriWave/ReactSiriWave";

import Progress from "./Progress";
import Question from "./Question";
import QuizFooter from "./QuizFooter";
import Popup from "./Popup";
import TimerPopup from "./TimerPopup";

import classes from "./LayoutQuiz.module.css";
import catchAsyncError from "../../api/catchError";

interface Props {
  submitHandler(): Promise<void>;
}

const LayoutQuiz: FC<Props> = ({ submitHandler }) => {
  const dispatch = useDispatch();
  const {
    appLayout,
    question,
    uploadResource,
    timeLimitForVoiceAnswerSec,
    localError,
    localErrorCaller,
  } = useSelector(getState);

  const [showPopup, setShowPopup] = useState(false);
  const [showTimePopup, setShowTimePopup] = useState(false);
  const [typewriting, setTypewriting] = useState(true);

  const {
    audioBlob,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    errorMessage,
    audioResult,
  } = useAudioRecorder();

  const startClickHandler = () => {
    startRecording();
  };

  const resumePressHandler = () => {
    resumeRecording();
  };

  const pauseClickHandler = () => {
    pauseRecording();
    setShowPopup(true);
  };

  const stopPressHandler = async () => {
    stopRecording();
    dispatch(updateProgressTime(timer));
  };

  const retryPressHandler = () => {
    switch (localErrorCaller) {
      case "recorder":
        switch (status) {
          case RECORD_STATUS.IDLE:
            startClickHandler();
            break;
          case RECORD_STATUS.PAUSED:
            resumePressHandler();
            break;
          default:
            resumePressHandler();
        }
        break;
      case "amazon":
        stopPressHandler();
        break;
    }
    console.log("retryPressHandler");
  };

  const confirmClickHandler = async () => {
    dispatch(updateLoadingMessage("Analyzing your answer"));
    await submitHandler();
  };

  if (timer >= timeLimitForVoiceAnswerSec + 60 && !showTimePopup) {
    pauseRecording();
    setShowTimePopup(true);
  }

  useEffect(() => {
    dispatch(updateLocalError({ error: errorMessage, caller: "recorder" }));
    if (errorMessage && errorMessage.length > 0) {
      setShowPopup(true);
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    const uploadFileToS3 = async (audioData: Blob): Promise<void> => {
      try {
        const response = await fetch(uploadResource.url, {
          method: "PUT",
          body: audioData,
          redirect: "follow",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.ok) {
          dispatch(updateLoadingMessage("Analyzing your answer"));
          await submitHandler();
        } else {
          const errorMessage = catchAsyncError(response.statusText);
          dispatch(updateLocalError({ error: errorMessage, caller: "amazon" }));
          dispatch(updateAppLayout("quiz"));
          setShowPopup(true);
        }
      } catch (error) {
        const errorMessage = catchAsyncError(error);
        dispatch(updateLocalError({ error: errorMessage, caller: "amazon" }));
        dispatch(updateAppLayout("quiz"));
        setShowPopup(true);
      }
    };
    const uploadToAmazon = async () => {
      dispatch(updateLoadingMessage("Uploading your response"));
      setShowPopup(false);
      setShowTimePopup(false);
      await uploadFileToS3(audioBlob);
    };
    if (audioResult && audioBlob) {
      uploadToAmazon();
    }
  }, [audioBlob, audioResult, dispatch]);

  return (
    <div
      className={`layout ${classes.quiz} ${
        appLayout === "quiz" ? `active ${classes.active}` : ""
      } ${appLayout === "thank" || appLayout === "404" ? classes.hide : ""} ${
        status === RECORD_STATUS.RECORDING ? classes.recording : ""
      }`}
    >
      <Progress active={appLayout === "quiz"} />

      <Question
        show={appLayout === "quiz" && question.length > 0}
        status={status}
        setTypewriting={setTypewriting}
      />

      <ReactSiriwave
        theme="ios9"
        color="#abdefc"
        autostart={true}
        curveDefinition={[
          { color: "212, 233, 255", supportLine: true },
          { color: "203, 227, 255" },
          { color: "235, 220, 255" },
          { color: "211, 244, 251" },
        ]}
      />

      <QuizFooter
        active={appLayout === "quiz"}
        status={status}
        timer={timer}
        startClickHandler={startClickHandler}
        pauseClickHandler={pauseClickHandler}
        confirmClickHandler={confirmClickHandler}
        typewriting={typewriting}
      />

      <Popup
        show={showPopup}
        setShowPopup={setShowPopup}
        type={localError ? "error" : "voice"}
        text={localError ? localError : "Are you ready to send your response?"}
        resumeRecording={resumePressHandler}
        stopRecording={stopPressHandler}
        retry={retryPressHandler}
      />
      <TimerPopup show={showTimePopup} stopRecording={stopPressHandler} />
    </div>
  );
};

export default LayoutQuiz;
