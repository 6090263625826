import classes from "./OnboardingRight.module.css";
import checkboxClasses from "./OnboardingCheckbox.module.css";
import { FC } from "react";

interface Props {
  isActive: boolean;
  isShown: boolean;
  title: string;
  text: string;
  checkboxClickHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  consentText: string;
}

const OnboardingCheckbox: FC<Props> = ({
  isActive,
  isShown,
  title,
  text,
  checkboxClickHandler,
  consentText,
}) => {
  return (
    <div
      className={`${classes.screenRightContent} ${
        isActive ? classes.active : ""
      } ${isShown ? classes.shown : ""}`}
    >
      <h3>{title}</h3>
      <div className={classes.screenText}>
        <p>{text}</p>
        <div className={checkboxClasses.checkbox}>
          <input
            id="consent"
            type="checkbox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              checkboxClickHandler(e);
            }}
          />
          <label htmlFor="consent">{consentText}</label>
        </div>
      </div>
    </div>
  );
};

export default OnboardingCheckbox;
