import { ChangeEvent, FC, useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { getState } from "../../store/slice";
import Button from "../Layout/Button";

import classes from "./Help.module.css";
import { getClient } from "../../api/client";

const hubspotURL =
  process.env.REACT_APP_HUBSPOT_URL ||
  "https://forms.hubspot.com/uploads/form/v2/22777383/f0bfdec9-ea02-49bf-8d5b-a732249854ae";

const animationTiming = {
  enter: 500,
  exit: 350,
};

const re =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Props {
  show?: boolean;
  setShowHelp(arg0: boolean): void;
}

const Help: FC<Props> = ({ show = false, setShowHelp }) => {
  const { sessionId } = useSelector(getState);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
    //setEmailError(!re.test(e.target.value.trim().toLowerCase()));
  };
  const messageChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    setMessageError(false);
    //setMessageError(e.target.value.trim().length === 0);
  };
  const submitClickHandler = async () => {
    if (!re.test(email)) {
      setEmailError(true);
      return;
    }
    if (message.length === 0) {
      setMessageError(true);
      return;
    }

    try {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("email", email);
      data.append("sessionId", sessionId);
      data.append("message", message);

      const client = await getClient({});
      await client.post(hubspotURL, data);

      setShowHelp(false);
    } catch (error) {
      console.log(error);
      setShowHelp(false);
      //const errorMessage = catchAsyncError(error);
      //dispatch(updateGlobalError(errorMessage));
    }
  };
  const closeHelpHandler = () => {
    setShowHelp(false);
  };
  return (
    <>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.OverlayOpen,
          exit: "",
          exitActive: classes.OverlayClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.overlay} onClick={closeHelpHandler}></div>
      </CSSTransition>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.ModalOpen,
          exit: "",
          exitActive: classes.ModalClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.help}>
          <button className={classes.close} onClick={closeHelpHandler}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="none"
            >
              <rect width="32" height="32" rx="8" fill="white" />
              <path
                d="M22 10L10 22M22 22L10 10"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <div className={classes.title}>Need Help?</div>
          <p>
            if you have questions or encounter bugs, write to us and we will
            definitely fix everything
          </p>
          <div className={classes.form}>
            <div className={classes.input}>
              <input
                className={emailError ? classes.hasError : ""}
                type="email"
                id="email"
                inputMode="email"
                placeholder="Please enter your email"
                value={email}
                onChange={emailChangeHandler}
              />
              <label htmlFor="email">Email</label>
              {emailError && (
                <div className={classes.error}>
                  Please enter a correct email
                </div>
              )}
            </div>
            <div className={classes.input}>
              <textarea
                className={messageError ? classes.hasError : ""}
                id="message"
                placeholder="Please enter your message"
                value={message}
                onChange={messageChangeHandler}
              />
              <label htmlFor="message">Message</label>
              {messageError && (
                <div className={classes.error}>Please enter your message</div>
              )}
            </div>
          </div>
          <div className={classes.buttons}>
            <Button small secondary label="Cancel" onClick={closeHelpHandler} />
            <Button small label="Send" onClick={submitClickHandler} />
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Help;
