import { FC } from "react";

import classes from "./Button.module.css";

interface Props {
  onClick?: () => void;
  secondary?: boolean;
  small?: boolean;
  label: string;
  className?: string;
}

const Button: FC<Props> = ({
  onClick,
  label,
  secondary = false,
  small = false,
  className = "",
}) => {
  return (
    <button
      onClick={onClick}
      className={`${classes.button} ${secondary ? classes.secondary : ""} ${
        small ? classes.small : ""
      } ${className ? classes[className] : ""}`}
    >
      <span>{label}</span>
    </button>
  );
};

export default Button;
