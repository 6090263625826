import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Typewriter from "typewriter-effect";
import { DotLottiePlayer } from "@dotlottie/react-player";

import { getState } from "../../store/slice";

import classes from "./Loading.module.css";

const tips = [
  "Interesting Fact: The most in-demand languages of the year include Python, JavaScript, and Go. ",
  "Tip: Regular refactoring can keep code clean and maintainable. It's a sign of proactive development practices.",
  "Professional Growth: Engaging in pair programming has been shown to enhance coding skills and team collaboration.",
  "Adaptation: The ability to learn new programming paradigms and frameworks is as valuable as mastering existing ones.",
  "Innovation: Contribution to open-source projects is a testament to a developer's commitment to collaborative improvement.",
  "Quality Indicator: Attention to detail in code review can significantly reduce future maintenance costs.",
  "We appreciate your patience, and hope you are enjoying these message :) "
];

const Loading: FC = () => {
  const { appLayout, loadingMessage } = useSelector(getState);
  const [tipIndex, setTipIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTipIndex((prevState) =>
        prevState === tips.length - 1 ? 0 : ++prevState
      );
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      className={`layout ${classes.loading} ${
        appLayout === "loading" ? `active ${classes.active}` : ""
      } ${
        appLayout === "quiz" ||
        appLayout === "thank" ||
        appLayout === "onboarding" ||
        appLayout === "404"
          ? classes.hide
          : ""
      }`}
    >
      <div className={classes.lottie}>
        <DotLottiePlayer
          src="/dotlottie/laptop.json"
          autoplay
          loop
        ></DotLottiePlayer>
        <div className={classes.tip}>
          <div className={classes.tipText}>{tips[tipIndex]}</div>
          <svg
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.8623 7.34746C28.3229 8.60077 29.3016 11.6115 28.0483 14.0721L17.6556 34.4762C16.4023 36.9369 13.3916 37.9156 10.931 36.6623C8.47035 35.409 7.49162 32.3982 8.74492 29.9376L19.1376 9.53351C20.3909 7.07289 23.4017 6.09416 25.8623 7.34746ZM42.1068 22.0408C44.221 23.8173 44.4948 26.9712 42.7184 29.0854L31.9857 41.8587C30.2093 43.9729 27.0554 44.2467 24.9412 42.4703C22.827 40.6939 22.5532 37.5399 24.3296 35.4257L35.0622 22.6524C36.8386 20.5382 39.9926 20.2644 42.1068 22.0408Z"
              fill="#5186EF"
            />
          </svg>
        </div>
      </div>
      <svg
        className={classes.loader}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="84" cy="50" r="10" fill="#5186ef">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="0.3333333333333333s"
            calcMode="spline"
            keyTimes="0;1"
            values="10;0"
            keySplines="0 0.5 0.5 1"
            begin="0s"
          ></animate>
          <animate
            attributeName="fill"
            repeatCount="indefinite"
            dur="1.3333333333333333s"
            calcMode="discrete"
            keyTimes="0;0.25;0.5;0.75;1"
            values="#5186ef;#5186ef;#5186ef;#5186ef;#5186ef"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="16" cy="50" r="10" fill="#5186ef">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.3333333333333333s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;10;10;10"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="0s"
          ></animate>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.3333333333333333s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="50" cy="50" r="10" fill="#5186ef">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.3333333333333333s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;10;10;10"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.3333333333333333s"
          ></animate>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.3333333333333333s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.3333333333333333s"
          ></animate>
        </circle>
        <circle cx="84" cy="50" r="10" fill="#5186ef">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.3333333333333333s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;10;10;10"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.6666666666666666s"
          ></animate>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.3333333333333333s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.6666666666666666s"
          ></animate>
        </circle>
        <circle cx="16" cy="50" r="10" fill="#5186ef">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.3333333333333333s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;10;10;10"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-1s"
          ></animate>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.3333333333333333s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-1s"
          ></animate>
        </circle>
      </svg>
      <div className={classes.message}>
        <Typewriter
          options={{
            strings: [loadingMessage],
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(Loading);
