import { FC } from "react";
import classes from "./OnboardingLeft.module.css";
import termsClasses from "./OnboardingTerms.module.css";

interface Props {
  isActive: boolean;
  isShown: boolean;
}

const OnboardingTerms: FC<Props> = ({ isActive, isShown }) => {
  return (
    <div
      className={`${classes.screenLeftContent} ${
        isActive ? termsClasses.active : ""
      } ${isShown ? termsClasses.shown : ""}`}
    >
      <div className={`${termsClasses.terms} `}>
        <div className={termsClasses.overflow}>
          <h4>Terms & Conditions</h4>
          <p>Welcome to Pragmata!</p>
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Pragmata's Website, located at pragmata.io. Pragmata is owned
            and operated by Urove LLC, a Delaware corporation.
          </p>
          <p>
            By accessing this website, we assume you accept these terms and
            conditions. Do not continue to use Pragmata if you do not agree to
            take all of the terms and conditions stated on this page.
          </p>
          <p>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice, and all Agreements:
            "Client" refers to the company, organization, or
            individual who uses Pragmata for talent assessment. "User" refers to
            the person using Pragmata as a job applicant. "The Company,"
            "Ourselves," "We," "Our," and "Us" refer to our Company. "Party,"
            "Parties," or "Us" refer to both the Client and ourselves. All terms
            refer to the offer, acceptance, and consideration of payment
            necessary to undertake the process of our assistance to the Client
            in the most appropriate manner for the express purpose of meeting
            the Client's needs in respect of the provision of the Company's
            stated services, in accordance with and subject to, prevailing law.
            Any use of the above terminology or other words in the singular,
            plural, capitalization, and/or he/she or they, are taken as
            interchangeable and therefore as referring to the same.
          </p>
          <h5>Cookies</h5>
          <p>
            We employ the use of cookies. By accessing Pragmata, you agreed to
            use cookies in agreement with Pragmata's Privacy Policy. Most
            interactive websites use cookies to let us retrieve the user's
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.
          </p>
          <h5>License</h5>
          <p>
            Unless otherwise stated, Pragmata and/or its licensors own the
            intellectual property rights for all material on Pragmata. All
            intellectual property rights are reserved. You may access this from
            Pragmata for your own personal use subject to restrictions set in
            these terms and conditions.
          </p>
          <h5>You must not:</h5>
          <ul>
            <li>Republish material from Pragmata</li>
            <li>Sell, rent, or sub-license material from Pragmata</li>
            <li>Reproduce, duplicate or copy material from Pragmata</li>
            <li>Redistribute content from Pragmata</li>
          </ul>
          <p>This Agreement shall begin on the date hereof.</p>
          <h5>Reservation of Rights</h5>
          <p>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amend these terms and conditions and its linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
          </p>
          <h5>Removal of links from our website</h5>
          <p>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us at any moment. We will
            consider requests to remove links but we are not obligated to do so
            or to respond to you directly.
          </p>
          <p>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </p>
          <h5>Professional Background Information</h5>
          <p>
            Pragmata systems may ask users about their professional background.
            By accepting these terms, you guarantee that you will not share any
            non-public, proprietary, personal sensitive, or confidential information with Pragmata.
            Should you share such information while using Pragmata, you should
            reach out to Pragmata support at support@pragmata.io and notify them as soon as possible.
          </p>
          <h5>Third-Party Talent Assessment Tool</h5>
          <p>
            Pragmata is a third-party talent assessment tool and is not involved
            in final hiring decisions made by its clients. Pragmata is not
            liable for any hiring or termination decisions made by its clients.
          </p>
          <h5>Roles Availability and Suspension of Evaluations</h5>
          <p>
            Pragmata retains the right to suspend evaluations for any role based on the Client's direction,
            due to technical difficulties, or per internal policy.
          </p>
          <h5>GDPR Compliance</h5>
          <h6>Data Collection and Use</h6>
          <p>
            We collect and use your personal data only as necessary to provide
            our services. By using Pragmata, you consent to the collection, use,
            and sharing of your personal data as outlined in our Privacy Policy.
            We ensure that your personal data is processed lawfully, fairly, and
            in a transparent manner.
          </p>
          <h6>Data Protection Rights</h6>
          <p>
            As a user, you have the right to access your personal data, correct
            any inaccuracies, request deletion of your data, restrict
            processing, and object to the processing of your data. You also have
            the right to data portability. To exercise these rights, please
            contact us at support@pragmata.io.
          </p>
          <h6>Data Security</h6>
          <p>
            We implement appropriate technical and organizational measures to
            ensure a level of security appropriate to the risk of processing
            your personal data. However, no method of transmission over the
            Internet or method of electronic storage is completely secure, and
            we cannot guarantee absolute security.
          </p>
          <h6>Data Retention</h6>
          <p>
            We retain your personal data only for as long as necessary to
            fulfill the purposes for which it was collected and to comply with
            legal, regulatory, or internal policy requirements.
          </p>
          <h6>International Data Transfers</h6>
          <p>
            If you are accessing our services from outside the United States,
            please be aware that your personal data may be transferred to,
            stored, and processed in the United States. By using our services,
            you consent to this transfer, storage, and processing of your
            information in the United States. If you don't want your data to be stored, transferred, and processed
            in the United States, please close this page now, and arrange an in-person
            evaluation with support@pragmata.io. We will take reasonable steps to arrange an in-person
            evaluation for you in due time.
          </p>
          <h6>Children's Privacy</h6>
          <p>
            Our services are not intended for individuals under the age of 16.
            We do not knowingly collect personal data from children under 16. If
            we become aware that a child under 16 has provided us with personal
            data, we will take steps to delete such information.
          </p>
          <p>
            By using Pragmata, you agree to comply with these terms and
            conditions. If you have any questions or concerns about these terms,
            please contact us at support@pragmata.io.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnboardingTerms;

