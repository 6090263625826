import { FC } from "react";
import { useSelector } from "react-redux";

import { getState } from "../../store/slice";

// import { secondsToTime } from "../../utils/date";

import classes from "./Progress.module.css";

interface Props {
  active?: boolean;
}

const Progress: FC<Props> = ({ active = false }) => {
  const { progressStepsCurrent, progressStepsTotal } = useSelector(getState);
  const progressPercent = Math.round(
    (progressStepsCurrent / progressStepsTotal) * 100
  );

  // const [timer, setTimer] = useState(0);

  // useEffect(() => {
  //   const timerFn = () => {
  //     setTimer((prevState) => ++prevState);
  //   };
  //   let interval = setInterval(timerFn, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <div className={`${classes.progress} ${active ? classes.active : ""}`}>
      <div className={classes.progressBar}>
        <div
          className={classes.progressLine}
          style={{ width: `${progressPercent}%` }}
        ></div>
        <div className={classes.progressCircle}></div>
      </div>
      <div
        className={classes.progressTime}
        style={{ left: `${progressPercent}%` }}
      >
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
          <circle cx="12.5" cy="12" r="6" fill="#EE3360" />
          <circle cx="12.5" cy="12" r="9" stroke="#EE3360" />
        </svg> */}
        <span>{`${progressPercent}%`}</span>
      </div>
    </div>
  );
};

export default Progress;
