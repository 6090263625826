import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getState, updateOnboardingScreenIndex } from "../../store/slice";

import OnboardingSteps from "./OnboardingSteps";
import OnboardingLeft from "./OnboardingLeft";
import OnboardingTerms from "./OnboardingTerms";
import BackNext from "./BackNext";
import Button from "../Layout/Button";

import classes from "./LayoutOnboarding.module.css";
import OnboardingRight from "./OnboardingRight";
import OnboardingCheckbox from "./OnboardingCheckbox";
import OnboardingCV from "./OnboardingCV";
import OnboardingJobHistory from "./OnboardingJobHistory";
import { SaveUserInfoSchema } from "../../@types/quiz";
import Cookies from "js-cookie";

export const ONBOARDING_SCREENS = {
  WELCOME: 0,
  AGREEMENTS: 1,
  UPLOAD_CV: 2,
  JOB_HISTORY: 3,
  VOICE_RECORDER: 4,
  BE_YOURSELF: 5,
  ADAPTIVE_SYSTEM: 6,
  COMFORTABLE_PLACE: 7,
};

interface Props {
  submitUserInfo: (user: SaveUserInfoSchema) => Promise<void>;
  getProjectData: (projectId: string) => Promise<void>;
  getUserPrompt: (querySessionId?: string) => Promise<void>;
}

const LayoutOnboarding: FC<Props> = ({
  submitUserInfo,
  getProjectData,
  getUserPrompt,
}) => {
  const dispatch = useDispatch();
  const { onboardingScreenIndex, userInfo, userLocalFinalized, appLayout } =
    useSelector(getState);
  const [checked, setChecked] = useState(false);
  const [cvUploaded, setCvUploaded] = useState(false);

  const startButtonClickHandler = () => {
    Cookies.set(
      "onboardingScreenIndex",
      ONBOARDING_SCREENS.AGREEMENTS.toString()
    );
    dispatch(updateOnboardingScreenIndex(ONBOARDING_SCREENS.AGREEMENTS));
  };
  const checkboxClickHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  return (
    <div
      className={`layout ${classes.onboarding} ${
        appLayout === "onboarding" ? `active ${classes.active}` : ""
      } ${appLayout === "quiz" || appLayout === "thank" ? classes.hide : ""}`}
    >
      <div className={classes.screens}>
        <div className="container">
          <div className={classes.screenContent}>
            <div className={classes.screenLeft}>
              <OnboardingLeft
                isActive={appLayout === "onboarding"}
                isShown={onboardingScreenIndex === ONBOARDING_SCREENS.WELCOME}
                title="Welcome"
                image="/onboarding/welcome.svg"
              />
              <OnboardingTerms
                isActive={appLayout === "onboarding"}
                isShown={
                  onboardingScreenIndex === ONBOARDING_SCREENS.AGREEMENTS
                }
              />
              <OnboardingCV
                isActive={appLayout === "onboarding"}
                isShown={onboardingScreenIndex === ONBOARDING_SCREENS.UPLOAD_CV}
                cvUploaded={cvUploaded}
                setCvUploaded={setCvUploaded}
                getUserPrompt={getUserPrompt}
              />
              <OnboardingJobHistory
                isActive={appLayout === "onboarding"}
                isShown={
                  onboardingScreenIndex === ONBOARDING_SCREENS.JOB_HISTORY
                }
                submitUserInfo={submitUserInfo}
              />
              <OnboardingLeft
                isActive={appLayout === "onboarding"}
                isShown={
                  onboardingScreenIndex === ONBOARDING_SCREENS.VOICE_RECORDER
                }
                title="Voice recorder"
                image="/onboarding/voice-recorder.svg"
              />
              <OnboardingLeft
                isActive={appLayout === "onboarding"}
                isShown={
                  onboardingScreenIndex === ONBOARDING_SCREENS.BE_YOURSELF
                }
                title="Be yourself"
                image="/onboarding/be-yourself.svg"
              />
              <OnboardingLeft
                isActive={appLayout === "onboarding"}
                isShown={
                  onboardingScreenIndex === ONBOARDING_SCREENS.ADAPTIVE_SYSTEM
                }
                title="Adaptive system"
                image="/onboarding/adaptive-system.svg"
              />
              <OnboardingLeft
                isActive={appLayout === "onboarding"}
                isShown={
                  onboardingScreenIndex === ONBOARDING_SCREENS.COMFORTABLE_PLACE
                }
                title="Comfortable place"
                image="/onboarding/comfortable-place.svg"
              />
            </div>
            <div className={classes.screenRight}>
              <div className={classes.steps}>
                <OnboardingSteps filled={onboardingScreenIndex + 1} />
              </div>
              <div className={classes.screenData}>
                <OnboardingRight
                  isActive={appLayout === "onboarding"}
                  isShown={onboardingScreenIndex === ONBOARDING_SCREENS.WELCOME}
                  title="Welcome"
                  text="<p>Welcome to your personalized interview session. You will be asked several questions about your previous professional experience.</p>"
                />
                <OnboardingCheckbox
                  isActive={appLayout === "onboarding"}
                  isShown={
                    onboardingScreenIndex === ONBOARDING_SCREENS.AGREEMENTS
                  }
                  title="Agreements"
                  text="Please review our company's terms and conditions to ensure you are well informed about our policies and procedures."
                  checkboxClickHandler={checkboxClickHandler}
                  consentText="I agree with Terms & Conditions"
                />
                <OnboardingRight
                  isActive={appLayout === "onboarding"}
                  isShown={
                    onboardingScreenIndex === ONBOARDING_SCREENS.UPLOAD_CV
                  }
                  title="Upload CV"
                  text="<p>The system does not yet have your resume. Please upload the document by dragging it to the right field, or by clicking on the “Browse” button.</p>"
                />
                <OnboardingRight
                  isActive={appLayout === "onboarding"}
                  isShown={
                    onboardingScreenIndex === ONBOARDING_SCREENS.JOB_HISTORY
                  }
                  title="Job History"
                  text="<p>We've attempted to parse your resume and extract some basic information along with the three most relevant positions that we will use for this assessment. Please correct any missing or inaccurate information.</p><p>Thank you for ensuring your details are up to date.</p>"
                />
                <OnboardingRight
                  isActive={appLayout === "onboarding"}
                  isShown={
                    onboardingScreenIndex === ONBOARDING_SCREENS.VOICE_RECORDER
                  }
                  title="Voice recorder"
                  text="<p>You can conveniently respond using your voice. Try to keep your answer within 3 minutes.</p>"
                />
                <OnboardingRight
                  isActive={appLayout === "onboarding"}
                  isShown={
                    onboardingScreenIndex === ONBOARDING_SCREENS.BE_YOURSELF
                  }
                  title="Be yourself"
                  text="<p>Aim to be relaxed and authentic in your responses. Your sincerity and unique experiences are what we truly value.</p>"
                />
                <OnboardingRight
                  isActive={appLayout === "onboarding"}
                  isShown={
                    onboardingScreenIndex === ONBOARDING_SCREENS.ADAPTIVE_SYSTEM
                  }
                  title="Adaptive system"
                  text="<p>In case further details are needed, the system will ask additional questions to clarify your responses. Thus re-recording your answers is not available.</p>"
                />
                <OnboardingRight
                  isActive={appLayout === "onboarding"}
                  isShown={
                    onboardingScreenIndex ===
                    ONBOARDING_SCREENS.COMFORTABLE_PLACE
                  }
                  title="Comfortable place"
                  text="<p>We recommend finding a quiet place for recording to reduce background noise and ensuring a stable internet connection for a seamless and professional interview experience.</p>"
                />
              </div>
              <div className={classes.backNext}>
                {onboardingScreenIndex === ONBOARDING_SCREENS.WELCOME ? (
                  <Button
                    onClick={startButtonClickHandler}
                    label="Start your Journey"
                  />
                ) : (
                  <BackNext
                    getProjectData={getProjectData}
                    backHidden={
                      onboardingScreenIndex ===
                        ONBOARDING_SCREENS.JOB_HISTORY &&
                      userInfo &&
                      userLocalFinalized
                    }
                    nextDisabled={
                      (onboardingScreenIndex ===
                        ONBOARDING_SCREENS.AGREEMENTS &&
                        !checked) ||
                      (onboardingScreenIndex === ONBOARDING_SCREENS.UPLOAD_CV &&
                        !cvUploaded) ||
                      (onboardingScreenIndex ===
                        ONBOARDING_SCREENS.JOB_HISTORY &&
                        !userLocalFinalized)
                    }
                    lastScreen={
                      onboardingScreenIndex ===
                      ONBOARDING_SCREENS.COMFORTABLE_PLACE
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutOnboarding;
