// Forked Local Version from
// https://www.npmjs.com/package/react-siriwave
// based on other library: https://www.npmjs.com/package/siriwave

import React, { useEffect, useRef } from "react";
import * as SiriWave from "./index";

declare type CurveStyle = "ios" | "ios9";

export declare type IReactSiriwaveProps = {
  theme?: CurveStyle;
  ratio?: number;
  speed?: number;
  amplitude?: number;
  frequency?: number;
  color?: string;
  cover?: boolean;
  width?: number;
  height?: number;
  autostart?: boolean;
  pixelDepth?: number;
  lerpSpeed?: number;
  curveDefinition?: SiriWave.ICurveDefinition[];
  onInit?: (siriwave: SiriWave.default) => void;
};

const ReactSiriwave = (props: IReactSiriwaveProps) => {
  const siriwaveRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const siriwave = new SiriWave.default({
      container: siriwaveRef.current!,
      style: "ios9",
      width:
        window.innerWidth > window.innerHeight
          ? Math.round(window.innerWidth * 0.59166666666666667)
          : window.innerWidth,
      height:
        window.innerWidth > window.innerHeight
          ? Math.round(window.innerHeight * 1)
          : Math.round(window.innerHeight * 1),
      speed: 0.2,
      amplitude: 1,
      frequency: 6,
      color: "#abdefc",
      cover: false,
      autostart: true,
      pixelDepth: 0.02,
      lerpSpeed: 0.01,
      curveDefinition: [
        { color: "212, 233, 255", supportLine: true },
        { color: "203, 227, 255" },
        { color: "235, 220, 255" },
        { color: "211, 244, 251" },
      ],

      // Additional SiriWave props
      globalCompositeOperation: "color",
      ranges: {
        noOfCurves: [7, 9],
        amplitude: [0.1, 0.2],
      },
    });

    return () => {
      siriwave.dispose();
    };
  }, []);
  return <div ref={siriwaveRef} style={{ backfaceVisibility: "hidden" }}></div>;
};

export default React.memo(ReactSiriwave);
