import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { getState } from "../../store/slice";

import Button from "../Layout/Button";

import classes from "./JobForm.module.css";
import { ISOtoYYYYMMDD, YYYYMMDDToISO, convertDate } from "../../utils/date";
import CalendarIcon from "../Layout/CalendarIcon";
import { SaveUserInfoSchema } from "../../@types/quiz";

const animationTiming = {
  enter: 500,
  exit: 350,
};

const re =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Props {
  show?: boolean;
  setShowPopup(arg0: boolean): void;
  submitUserInfo: (user: SaveUserInfoSchema) => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const JobForm: FC<Props> = ({
  show,
  setShowPopup,
  submitUserInfo,
  setIsLoading,
}) => {
  const { userInfo } = useSelector(getState);
  const { positions } = userInfo;

  const scrollRef = useRef<HTMLDivElement>(null);

  const [firstName, setFirstName] = useState(userInfo.firstName);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState(userInfo.lastName);
  const [lastNameError, setLastNameError] = useState(false);
  const [email, setEmail] = useState(userInfo.email);
  const [emailError, setEmailError] = useState(false);

  const [company0Name, setCompany0Name] = useState("");
  const [company0NameError, setCompany0NameError] = useState(false);
  const [company0Title, setCompany0Title] = useState("");
  const [company0StartDate, setCompany0StartDate] = useState("");
  const [company0StartDateError, setCompany0StartDateError] = useState(false);
  const [company0EndDate, setCompany0EndDate] = useState("");
  const [company0EndDateError, setCompany0EndDateError] = useState(false);
  const [company0Present, setCompany0Present] = useState(false);

  const [company1Name, setCompany1Name] = useState("");
  const [company1NameError, setCompany1NameError] = useState(false);
  const [company1Title, setCompany1Title] = useState("");
  const [company1StartDate, setCompany1StartDate] = useState("");
  const [company1StartDateError, setCompany1StartDateError] = useState(false);
  const [company1EndDate, setCompany1EndDate] = useState("");
  const [company1EndDateError, setCompany1EndDateError] = useState(false);
  const [company1Present, setCompany1Present] = useState(false);

  const [company2Name, setCompany2Name] = useState("");
  const [company2NameError, setCompany2NameError] = useState(false);
  const [company2Title, setCompany2Title] = useState("");
  const [company2StartDate, setCompany2StartDate] = useState("");
  const [company2StartDateError, setCompany2StartDateError] = useState(false);
  const [company2EndDate, setCompany2EndDate] = useState("");
  const [company2EndDateError, setCompany2EndDateError] = useState(false);
  const [company2Present, setCompany2Present] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);

  const firstNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setFirstNameError(false);
  };
  const lastNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    setLastNameError(false);
  };
  const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const company0NameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany0Name(e.target.value);
    setCompany0NameError(false);
  };
  const company0TitleChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany0Title(e.target.value);
  };
  const company0StartDateChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany0StartDate(e.target.value);
    setCompany0StartDateError(false);
  };
  const company0EndDateChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany0EndDate(e.target.value);
    setCompany0EndDateError(false);
  };

  const company1NameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany1Name(e.target.value);
    setCompany1NameError(false);
  };
  const company1TitleChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany1Title(e.target.value);
  };
  const company1StartDateChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany1StartDate(e.target.value);
    setCompany1StartDateError(false);
  };
  const company1EndDateChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany1EndDate(e.target.value);
    setCompany1EndDateError(false);
  };

  const company2NameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany2Name(e.target.value);
    setCompany2NameError(false);
  };
  const company2TitleChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany2Title(e.target.value);
  };
  const company2StartDateChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany2StartDate(e.target.value);
    setCompany2StartDateError(false);
  };
  const company2EndDateChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany2EndDate(e.target.value);
    setCompany2EndDateError(false);
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop } = scrollRef.current;
      setScrollPosition(scrollTop);
    }
  };

  const company0checkboxClickHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompany0Present(e.target.checked);
    setCompany0EndDate("");
    setCompany0EndDateError(false);
    scrollRef.current?.scrollTo({
      top: scrollPosition,
    });
  };
  const company1checkboxClickHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompany1Present(e.target.checked);
    setCompany1EndDate("");
    setCompany1EndDateError(false);
    scrollRef.current?.scrollTo({
      top: scrollPosition,
    });
  };
  const company2checkboxClickHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompany2Present(e.target.checked);
    setCompany2EndDate("");
    setCompany2EndDateError(false);
    scrollRef.current?.scrollTo({
      top: scrollPosition,
    });
  };

  const closePopupHandler = () => {
    setShowPopup(false);
    resetForm();
  };
  const resetForm = () => {
    setFirstName(userInfo.firstName || "");
    setLastName(userInfo.lastName || "");
    setEmail(userInfo.email || "");

    setCompany0Name(
      positions[0] && positions[0].company ? positions[0].company : ""
    );
    setCompany0Title(
      positions[0] && positions[0].title ? positions[0].title : ""
    );
    setCompany0StartDate(
      positions[0] && positions[0].startDate
        ? ISOtoYYYYMMDD(positions[0].startDate)
        : ""
    );
    setCompany0EndDate(
      positions[0] && positions[0].endDate
        ? ISOtoYYYYMMDD(positions[0].endDate)
        : ""
    );
    if (positions[0] && positions[0].startDate && !positions[0].endDate)
      setCompany0Present(true);

    setCompany1Name(
      positions[1] && positions[1].company ? positions[1].company : ""
    );
    setCompany1Title(
      positions[1] && positions[1].title ? positions[1].title : ""
    );
    setCompany1StartDate(
      positions[1] && positions[1].startDate
        ? ISOtoYYYYMMDD(positions[1].startDate)
        : ""
    );
    setCompany1EndDate(
      positions[1] && positions[1].endDate
        ? ISOtoYYYYMMDD(positions[1].endDate)
        : ""
    );
    if (positions[1] && positions[1].startDate && !positions[1].endDate)
      setCompany1Present(true);

    setCompany2Name(
      positions[2] && positions[2].company ? positions[2].company : ""
    );
    setCompany2Title(
      positions[2] && positions[2].title ? positions[2].title : ""
    );
    setCompany2StartDate(
      positions[2] && positions[2].startDate
        ? ISOtoYYYYMMDD(positions[2].startDate)
        : ""
    );
    setCompany2EndDate(
      positions[2] && positions[2].endDate
        ? ISOtoYYYYMMDD(positions[2].endDate)
        : ""
    );
    if (positions[2] && positions[2].startDate && !positions[2].endDate)
      setCompany2Present(true);

    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setCompany0NameError(false);
    setCompany0StartDateError(false);
    setCompany0EndDateError(false);
    setCompany1NameError(false);
    setCompany1StartDateError(false);
    setCompany1EndDateError(false);
    setCompany2NameError(false);
    setCompany2StartDateError(false);
    setCompany2EndDateError(false);
  };
  const validateForm = () => {
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setCompany0NameError(false);
    setCompany0StartDateError(false);
    setCompany0EndDateError(false);
    setCompany1NameError(false);
    setCompany1StartDateError(false);
    setCompany1EndDateError(false);
    setCompany2NameError(false);
    setCompany2StartDateError(false);
    setCompany2EndDateError(false);

    let hasErrors = false;

    if (!firstName.trim().length) {
      setFirstNameError(true);
      hasErrors = true;
    }
    if (!lastName.trim().length) {
      setLastNameError(true);
      hasErrors = true;
    }
    if (!re.test(email.trim().toLowerCase())) {
      setEmailError(true);
      hasErrors = true;
    }
    if (!company0Name.trim().length) {
      setCompany0NameError(true);
      hasErrors = true;
    }
    if (!company0StartDate.trim().length) {
      setCompany0StartDateError(true);
      hasErrors = true;
    }
    if (!company0Present && !company0EndDate.trim().length) {
      setCompany0EndDateError(true);
      hasErrors = true;
    }
    if (
      company1Name.trim().length ||
      company1Title.trim().length ||
      company1StartDate.trim().length ||
      company1EndDate.trim().length ||
      company1Present
    ) {
      if (!company1Name.trim().length) {
        setCompany1NameError(true);
        hasErrors = true;
      }
      if (!company1StartDate.trim().length) {
        setCompany1StartDateError(true);
        hasErrors = true;
      }
      if (!company1Present && !company1EndDate.trim().length) {
        setCompany1EndDateError(true);
        hasErrors = true;
      }
    }
    if (
      company2Name.trim().length ||
      company2Title.trim().length ||
      company2StartDate.trim().length ||
      company2EndDate.trim().length ||
      company2Present
    ) {
      if (!company2Name.trim().length) {
        setCompany2NameError(true);
        hasErrors = true;
      }
      if (!company2StartDate.trim().length) {
        setCompany2StartDateError(true);
        hasErrors = true;
      }
      if (!company2Present && !company2EndDate.trim().length) {
        setCompany2EndDateError(true);
        hasErrors = true;
      }
    }

    if (!hasErrors) {
      let user: SaveUserInfoSchema = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        position0Name: company0Name.trim(),
        position0Title: company0Title.trim(),
        position0StartDate: YYYYMMDDToISO(company0StartDate.trim()),
        position0EndDate: company0Present
          ? ""
          : YYYYMMDDToISO(company0EndDate.trim()),
      };
      if (company1Name) {
        user.position1Name = company1Name.trim();
        user.position1Title = company1Title.trim();
        user.position1StartDate = YYYYMMDDToISO(company1StartDate.trim());
        user.position1EndDate = company1Present
          ? ""
          : YYYYMMDDToISO(company1EndDate.trim());
      }
      if (company2Name) {
        user.position2Name = company2Name.trim();
        user.position2Title = company2Title.trim();
        user.position2StartDate = YYYYMMDDToISO(company2StartDate.trim());
        user.position2EndDate = company2Present
          ? ""
          : YYYYMMDDToISO(company2EndDate.trim());
      }
      setShowPopup(false);
      setIsLoading(true);
      submitUserInfo(user);
    }
  };

  useEffect(() => {
    resetForm();
  }, [userInfo]);

  return (
    <>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.OverlayOpen,
          exit: "",
          exitActive: classes.OverlayClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.overlay}></div>
      </CSSTransition>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.ModalOpen,
          exit: "",
          exitActive: classes.ModalClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.popup}>
          <div className={classes.popupContent}>
            <div
              className={classes.popupScroll}
              ref={scrollRef}
              onScroll={handleScroll}
            >
              <div
                className={`${classes.formLine} ${classes.split} ${classes.deviceNoSplit}`}
              >
                <div className={classes.input}>
                  <input
                    className={firstNameError ? classes.hasError : ""}
                    type="text"
                    id="firstName"
                    inputMode="text"
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={firstNameChangeHandler}
                  />
                  <label htmlFor="email">First Name</label>
                  {firstNameError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
                <div className={classes.input}>
                  <input
                    className={lastNameError ? classes.hasError : ""}
                    type="text"
                    id="lastName"
                    inputMode="text"
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={lastNameChangeHandler}
                  />
                  <label htmlFor="lastName">Last Name</label>
                  {lastNameError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
              </div>
              <div className={`${classes.formLine}`}>
                <div className={classes.input}>
                  <input
                    className={emailError ? classes.hasError : ""}
                    type="email"
                    id="email"
                    inputMode="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={emailChangeHandler}
                  />
                  <label htmlFor="email">Email</label>
                  {emailError && (
                    <div className={classes.error}>
                      Please enter a correct email
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.spacer}></div>
              <div className={`${classes.formLine}`}>
                <div className={classes.input}>
                  <input
                    className={company0NameError ? classes.hasError : ""}
                    type="text"
                    id="company0Name"
                    inputMode="text"
                    placeholder="Enter company name"
                    value={company0Name}
                    onChange={company0NameChangeHandler}
                  />
                  <label htmlFor="company0Name">Company Name</label>
                  {company0NameError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
              </div>
              <div className={`${classes.formLine}`}>
                <div className={classes.input}>
                  <input
                    type="text"
                    id="company0Title"
                    inputMode="text"
                    placeholder="Enter title"
                    value={company0Title}
                    onChange={company0TitleChangeHandler}
                  />
                  <label htmlFor="company0Title">Title</label>
                </div>
              </div>
              <div className={`${classes.formLine} ${classes.split}`}>
                <div className={classes.input}>
                  <div
                    className={`${classes.dateValue} ${
                      !company0StartDate ? classes.empty : ""
                    }`}
                  >
                    <div className={classes.dateValueDate}>
                      {convertDate(company0StartDate)}
                    </div>
                    <div className={classes.dateValueIcon}>
                      <CalendarIcon />
                    </div>
                  </div>
                  <input
                    className={company0StartDateError ? classes.hasError : ""}
                    type="date"
                    inputMode="text"
                    value={company0StartDate}
                    onChange={company0StartDateChangeHandler}
                  />
                  <label>Start Date</label>
                  {company0StartDateError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
                <div
                  className={`${classes.input} ${
                    company0Present ? classes.disabled : ""
                  }`}
                >
                  <div
                    className={`${classes.dateValue} ${
                      !company0EndDate ? classes.empty : ""
                    }`}
                  >
                    <div className={classes.dateValueDate}>
                      {company0Present ? "—" : convertDate(company0EndDate)}
                    </div>
                    <div className={classes.dateValueIcon}>
                      <CalendarIcon />
                    </div>
                  </div>
                  <input
                    className={company0EndDateError ? classes.hasError : ""}
                    type="date"
                    inputMode="text"
                    value={company0EndDate}
                    onChange={company0EndDateChangeHandler}
                  />
                  <label>End Date</label>
                  {company0EndDateError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
              </div>
              <div className={`${classes.formLine}`}>
                <div className={classes.checkbox}>
                  <input
                    id="company0checkbox"
                    type="checkbox"
                    checked={company0Present}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      company0checkboxClickHandler(e);
                    }}
                  />
                  <label htmlFor="company0checkbox">
                    I am currently working in this company
                  </label>
                </div>
              </div>
              <div className={classes.hr}></div>
              <div className={`${classes.formLine}`}>
                <div className={classes.input}>
                  <input
                    className={company1NameError ? classes.hasError : ""}
                    type="text"
                    id="company1Name"
                    inputMode="text"
                    placeholder="Enter company name"
                    value={company1Name}
                    onChange={company1NameChangeHandler}
                  />
                  <label htmlFor="company1Name">Company Name</label>
                  {company1NameError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
              </div>
              <div className={`${classes.formLine}`}>
                <div className={classes.input}>
                  <input
                    type="text"
                    id="company1Title"
                    inputMode="text"
                    placeholder="Enter title"
                    value={company1Title}
                    onChange={company1TitleChangeHandler}
                  />
                  <label htmlFor="company1Title">Title</label>
                </div>
              </div>
              <div className={`${classes.formLine} ${classes.split}`}>
                <div className={classes.input}>
                  <div
                    className={`${classes.dateValue} ${
                      !company1StartDate ? classes.empty : ""
                    }`}
                  >
                    <div className={classes.dateValueDate}>
                      {convertDate(company1StartDate)}
                    </div>
                    <div className={classes.dateValueIcon}>
                      <CalendarIcon />
                    </div>
                  </div>
                  <input
                    className={company1StartDateError ? classes.hasError : ""}
                    type="date"
                    inputMode="text"
                    value={company1StartDate}
                    onChange={company1StartDateChangeHandler}
                  />
                  <label>Start Date</label>
                  {company1StartDateError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
                <div
                  className={`${classes.input} ${
                    company1Present ? classes.disabled : ""
                  }`}
                >
                  <div
                    className={`${classes.dateValue} ${
                      !company1EndDate ? classes.empty : ""
                    }`}
                  >
                    <div className={classes.dateValueDate}>
                      {company1Present ? "—" : convertDate(company1EndDate)}
                    </div>
                    <div className={classes.dateValueIcon}>
                      <CalendarIcon />
                    </div>
                  </div>
                  <input
                    className={company1EndDateError ? classes.hasError : ""}
                    type="date"
                    inputMode="text"
                    value={company1EndDate}
                    onChange={company1EndDateChangeHandler}
                  />
                  <label>End Date</label>
                  {company1EndDateError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
              </div>
              <div className={`${classes.formLine}`}>
                <div className={classes.checkbox}>
                  <input
                    id="company1checkbox"
                    type="checkbox"
                    checked={company1Present}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      company1checkboxClickHandler(e);
                    }}
                  />
                  <label htmlFor="company1checkbox">
                    I am currently working in this company
                  </label>
                </div>
              </div>
              <div className={classes.hr}></div>
              <div className={`${classes.formLine}`}>
                <div className={classes.input}>
                  <input
                    className={company2NameError ? classes.hasError : ""}
                    type="text"
                    id="company2Name"
                    inputMode="text"
                    placeholder="Enter company name"
                    value={company2Name}
                    onChange={company2NameChangeHandler}
                  />
                  <label htmlFor="company2Name">Company Name</label>
                  {company2NameError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
              </div>
              <div className={`${classes.formLine}`}>
                <div className={classes.input}>
                  <input
                    type="text"
                    id="company2Title"
                    inputMode="text"
                    placeholder="Enter title"
                    value={company2Title}
                    onChange={company2TitleChangeHandler}
                  />
                  <label htmlFor="company2Title">Title</label>
                </div>
              </div>
              <div className={`${classes.formLine} ${classes.split}`}>
                <div className={classes.input}>
                  <div
                    className={`${classes.dateValue} ${
                      !company2StartDate ? classes.empty : ""
                    }`}
                  >
                    <div className={classes.dateValueDate}>
                      {convertDate(company2StartDate)}
                    </div>
                    <div className={classes.dateValueIcon}>
                      <CalendarIcon />
                    </div>
                  </div>
                  <input
                    className={company2StartDateError ? classes.hasError : ""}
                    type="date"
                    inputMode="text"
                    value={company2StartDate}
                    onChange={company2StartDateChangeHandler}
                  />
                  <label>Start Date</label>
                  {company2StartDateError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
                <div
                  className={`${classes.input} ${
                    company2Present ? classes.disabled : ""
                  }`}
                >
                  <div
                    className={`${classes.dateValue} ${
                      !company2EndDate ? classes.empty : ""
                    }`}
                  >
                    <div className={classes.dateValueDate}>
                      {company2Present ? "—" : convertDate(company2EndDate)}
                    </div>
                    <div className={classes.dateValueIcon}>
                      <CalendarIcon />
                    </div>
                  </div>
                  <input
                    className={company2EndDateError ? classes.hasError : ""}
                    type="date"
                    inputMode="text"
                    value={company2EndDate}
                    onChange={company2EndDateChangeHandler}
                  />
                  <label>End Date</label>
                  {company2EndDateError && (
                    <div className={classes.error}>Required</div>
                  )}
                </div>
              </div>
              <div className={`${classes.formLine}`}>
                <div className={classes.checkbox}>
                  <input
                    id="company2checkbox"
                    type="checkbox"
                    checked={company2Present}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      company2checkboxClickHandler(e);
                    }}
                  />
                  <label htmlFor="company2checkbox">
                    I am currently working in this company
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.popupFooter}>
            <Button secondary onClick={closePopupHandler} label="Cancel" />
            <Button onClick={validateForm} label="Save" />
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default JobForm;
